import { put, takeLatest } from "redux-saga/effects";
import { obtenerEntradas, obtenerEntrada } from '../api';

import { GET_ENTRADAS_REQUEST, GET_ENTRADAS_SUCCESS, GET_ENTRADA_REQUEST, GET_ENTRADA_SUCCESS } from "../actions";

function* getEntradas() {
    try {
        const res = yield obtenerEntradas();
        yield put( { type: GET_ENTRADAS_SUCCESS, payload: res.data.entradas } );
    } catch ( error ) {
        console.log(error);
    }
}

function* getEntrada(payload) {
    try {
        const res = yield obtenerEntrada(payload.id);
        yield put( { type: GET_ENTRADA_SUCCESS, payload: res.data.entrada } );
    } catch ( error ) {
        console.log(error);
    }
}

// Watcher
export default function* portafolioSaga() {
    yield takeLatest( GET_ENTRADAS_REQUEST, getEntradas );
    yield takeLatest( GET_ENTRADA_REQUEST, getEntrada );
}

