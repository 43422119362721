import { put, takeLatest, select } from "redux-saga/effects";

import {
    ERROR_CARGAR_REGISTROS,
} from "../ActionTypes";
import {  getVehiculos } from '../api';

import { SOLICITAR_REGISTROS, REGISTROS_RECIBIDOS, } from "../actions";


function* pedirVehiculos() {
    try {
        const res = yield getVehiculos();
        const data = res.data;
        yield put( { type: REGISTROS_RECIBIDOS, payload: data } );
    } catch ( error ) {
        yield put( {
            type: ERROR_CARGAR_REGISTROS,
            payload: {
                title: "Querido usuario, lo sentimos hubo un error",
                debug: error,
            },
        } );
    }
}

//Watcher
export default function* vehiculosSaga() {
    yield takeLatest( SOLICITAR_REGISTROS, pedirVehiculos );
}

