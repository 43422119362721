import axios from 'axios';
import { constCongif } from '../../utils/Constants';
const urlBase = constCongif.url.API_URL;

export function fetchRegistros( token ) {
    const config = tokenConfig( token );
    return axios.get( urlBase + '/api/users/091071d04348ee8f04211d70b9b5518d', config );
}

export function dscRegistros() {
    return axios.get( urlBase + '/api/users/b7e07d91c6bc90721191405078c863f2' );
}

export function updateRegistros() {
    return axios.put( urlBase + '/api/users/484d1626c0ca153da0bc3c117b230df7' );
}

/* SOLICITUDES DE SEGURIDAD */
export function verificarToken( id, token ) {
    const config = tokenConfig( token );
    return axios.post( urlBase + '/api/auth/' + id, null, config );
}

export function login( email, pass, config ) {
    const params = {
        email: email,
        pass: pass,
    };
    return axios.post( urlBase + '/api/auth/', params, config );
}

/* SOLICITUDES DE USUARIO */
export function actualizarUserData( id, data, token ) {
    const config = tokenConfig( token );
    return axios.put( urlBase + '/api/users/' + id, data, config );
}

export function nuevoRegistro( email, pass, userName ) {
    const params = {
        email: email,
        pass: pass,
        userName: userName,
    };
    const config = tokenConfig();
    return axios.post( urlBase + '/api/registro/', params, config );
}

export function traerRegistros( id, data, token ) {
    const config = tokenConfig( token );
    return axios.get( urlBase + '/api/users/' + id, data, config );
}

export function updatePass( id, params, token ) {
    const config = tokenConfig( token );
    return axios.put( urlBase + '/api/auth/' + id, params, config );
}

/* SOLICITUDES DE PORTAFOLIO */
export function newPortfolioItem( id, params, token ) {
    const config = tokenConfig( token );
    return axios.post( urlBase + '/api/vehiculos/' + id, params, config );
}

export function editPortfolioItem( itemId, params, token ) {
    const config = tokenConfig( token );
    return axios.put( urlBase + '/api/vehiculos/' + itemId, params, config );
}

export function getVehiculos( token ) {
    return axios.get( urlBase + '/api/vehiculos/' );
}

// SOLICUTUD COMPARACIONES
export function obtenerComparaciones() {
    return axios.get( urlBase + '/api/comparacion/' );
}

// SOLICITUD ENTRADAS
export function obtenerEntradas() {
    return axios.get( urlBase + '/api/entradas/' );
}

export function obtenerEntrada(id) {
    return axios.get( urlBase + `/api/entradas/${id}` );
}

// SOLICITUD MENSAJES
export function enviarMensaje(data) {
    console.log(data);
    return axios.post( urlBase + '/api/emails/', data );
}

/* HELPERS */
export async function loadImages( data ) {
    // eslint-disable-next-line prefer-const
    let cargadas = [ ];
    let cargar = false;
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    data.forEach( file => {
        if ( file.size ) {
            cargar = true;
            formData.append( "file", file );
        } else {
            cargadas.push( file );
        }
    } );
    
    let res = {};
    if ( cargar ) {
        res = await axios.post( urlBase + "/api/upload/", formData, 
            { headers: { "Content-Type": "multipart/form-data" } } );
    } else {
        res.data = [];
    }
    const respuesta = res.data.concat( cargadas );
    return respuesta;
}


function tokenConfig( token ) {
    //HEADERS
    const config = {
        headers: {
            "Content-Type": "application/json",
        },
    };
    //IF TOKEN
    if ( token ) {
        config.headers[ "x-auth-token" ] = token; 
    }
    return config;
}

