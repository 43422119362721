import React from "react";
import { Link } from "react-router-dom";

/** BOOTSTRAP */
import { Container, Row, Col, Nav } from "react-bootstrap";

/*Icons */
import { IoLogoWhatsapp } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";

/*ASSETS */
import LogoEnergia from "../assets/images/LogoEnergia.svg";

const pie = () => {
  return (
    <Container fluid className="footerWeb mt-0">
      <Row className="footerSuperior container">
        <Col md={11}>
          <Row>
            <Col md={3}>
              <h5>Horarios de atención</h5>
              <Nav className="flex-column">
                <span>
                  Lunes a viernes 9:00am 1:00 pm. - 2.00pm. a 6:00 pm. Sábados
                  8:00am - 12:00m
                </span>
              </Nav>
            </Col>
            <Col md={5}>
              <Row>
              <h5>Pereira</h5>
              </Row>
              <Row>
                <Col md={6}>
                  <span>
                    <FaBuilding />{" "}
                    Edificio Torre Central
                    <br />
                    Cra 10 #17-55. Local 206. <br />
                    <IoLogoWhatsapp /> 316 301 4346<br/> 316 422 8623{" "}
                    <br /><MdEmail />{" "}
                    asesormeep1@eep.com.co
                  </span>
                </Col>
                <Col md={6}>
                  <span>
                    <FaBuilding />{" "}
                    Electrolinera
                    <br />
                    <a target="_blank" href="https://goo.gl/maps/2A2GvT2MnGPYZK1W8?coh=178571&entry=tt" rel="noreferrer">Av. la Independencia con Cll 71 detrás del Éxito de cuba</a><br />
                    <IoLogoWhatsapp /> 313 810 1290{" "}
                    <MdEmail />{" "}
                    asesormeep1@eep.com.co 
                  </span>
                </Col>
              </Row>

            </Col>
            <Col md={3}>
              <h5>Cartago</h5>
              <span>
                <FaBuilding />{" "}
                Carrera 4 # 15 – 104 Local 1
                <br />
                <IoLogoWhatsapp /> 313 3054528{" "}
                <br />
                <MdEmail />{" "}asesormeep3@eep.com.co
              </span>
            </Col>
          </Row>
        </Col>
        <Col md={1} className="m-auto">
          <a target="_blank" href="http://eep.com.co/" rel="noreferrer">
            <img src={LogoEnergia} alt="Logo EEP" />
          </a>
        </Col>
      </Row>
      <Row className="footerInferior p-2">
        <Col md={6}>
          <span>
            2023 Empresa de Energía de Pereira. Todos los derechos reservados
          </span>
        </Col>
        <Col md={6}>
          <Nav className="justify-content-end">
            <Link to="/" className="nav-link">
              Términos
            </Link>
            <Link to="/" className="nav-link">
              Privacidad
            </Link>
            <a
              href="http://www.eep.com.co/images/stories/Politica_de_tratamiento_de_datos_personales.pdf"
              target="_blank"
              className="nav-link" rel="noreferrer"
            >
              Política de datos
            </a>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default pie;
