import React from "react";

/** BOOTSTRAP */
import { Container, Row, Col, Carousel } from "react-bootstrap";

/*ASSETS */
import imgDestacada1 from "../assets/images/imgDestacada1.jpg";
import imgElectrolinera from "../assets/images/electrolinera.png";

import "react-slideshow-image/dist/styles.css";
import { constCongif } from "../utils/Constants";
/**Icons */
import {
  GiReceiveMoney,
  GiStopSign,
  GiSoundWaves,
  GiEarthAmerica,
} from "react-icons/gi";
import { FaSolarPanel } from "react-icons/fa";

export const QuienesSomos = () => {
  const urlBase = constCongif.url.API_URL;
  return (
    <Container fluid className="sobreMeep">
      <Row className="banner position-relative">
        <Col md={12} className="px-0">
          <Carousel easing="ease" autoPlay={false}>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner7.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner8.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="position-absolute">
          <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
          <h1>Muévete por el futuro</h1>
        </Col>
      </Row>
      <Row className="pt-5 container mx-auto">
        <Col md={6} className="px-md-4">
          <h4>Sobre nosotros</h4>
          <p>
            Somos la Empresa de Energía de Pereira, una empresa mixta de
            servicios públicos domiciliarios, cuyo régimen jurídico se rige
            exclusivamente por el derecho privado, con cerca de 230.000
            usuarios. Nos dedicamos a las actividades de generación,
            distribución y comercialización de energía eléctrica. Tenemos
            presencia en los departamentos de Risaralda, Valle del Cauca,
            Magdalena, Guajira y Atlántico.
          </p>
          <p>
            Somos una empresa con grandes sueños. No solo comercializamos
            energía sino que además brindamos soluciones energéticas sostenibles
            como: movilidad eléctrica, sistemas solares fotovoltaicos,
            electrodomésticos eficientes y tenemos una cultura organizacional
            enfocada hacia la responsabilidad social y la protección del medio
            ambiente.{" "}
          </p>
          <p>
            Así nace nuestra unidad de negocio: Movilidad Eléctrica Energía de
            Pereira MEEP.
          </p>
        </Col>
        <Col md={6} className="px-md-4">
          <h4>Meep</h4>
          <p>
            Tenemos el propósito de hacer de Pereira la ciudad insignia en
            movilidad eléctrica, por eso comercializamos motociclos, bicicletas
            y patinetas eléctricas como una solución inteligente de transporte
            en la ciudad. Además estamos construyendo un corredor de movilidad
            eléctrica, ya contamos con ocho puntos de carga en Pereira y estamos
            estableciendo convenios con otros departamentos para ampliar este
            corredor por todo el Eje Cafetero.
          </p>
          <p>
            Los vehículos eléctricos funcionan con energía limpia, no tienen
            emisiones de CO2 y son mucho más económicos que transportarse con
            gasolina. Al ser una movilidad amigable con el medio ambiente, estos
            vehículos tienen beneficios tributarios, la revisión tecnicomecánica
            y el SOAT son más económicos y están exentos de pico y placa.
          </p>
        </Col>
      </Row>
      <Row
        className="py-5 tituloDestacado"
        style={{ backgroundColor: "#7d9417" }}
      >
        <Col md={10} className="mx-auto">
          <h1 className="m-0 p-0 d-inline-block">Electrolinera</h1>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6, offset: 1 }} className="px-3">
          <Row className="sobre-destacados h-100">
            <Col md={6} className="destacado m-auto">
              <FaSolarPanel size={52} color="#7d9417" />
              <h4>La primera electrolinera del país</h4>
              <p>
                En el 2018 entregamos a la ciudad la primera electrolinera del
                país o estación de carga rápida para vehículos eléctricos, esta
                cuenta con conectores de tipo europeos y americanos para atender
                cualquier vehículo. Allí se pueden cargar hasta dos carros de
                manera simultánea, además de bicicletas y patinetas.
              </p>
            </Col>
            <Col md={6} className="destacado m-auto">
              <ul>
                <li>
                  La estación cuenta con 24 paneles solares instalados sobre
                  techo que aportan mensualmente 640 kW/h.
                </li>
                <li>
                  2,7 Toneladas de CO2 al año aproximadamente, por usar un carro
                  eléctrico.
                </li>
                <li>
                  1,7 Toneladas de CO2 al año aproximadamente, por usar un
                  motociclo eléctrico.
                </li>
                <li>
                  Ahorros entre el 60 % y 70 % en combustible y mantenimiento
                  comparados con los vehículos tradicionales de combustible.
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 4, offset: 1 }} className="px-0">
          <img
            src={imgElectrolinera}
            alt="Destacado Meep"
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
      <Row className="motociclosClr py-5 tituloDestacado">
        <Col md={10} className="mx-auto">
          <h1 className="m-0 p-0 d-inline-block">Beneficios </h1>
          <h2 className="m-0 p-0 d-inline-block ml-3">Movilidad Eléctrica </h2>
        </Col>
      </Row>
      <Row>
        <Col md={3} className="px-0">
          <img src={imgDestacada1} alt="Destacado Meep" />
        </Col>
        <Col md={{ span: 8, offset: 1 }} className="px-3">
          <Row className="sobre-destacados">
            <Col md={5} className="destacado">
              <GiReceiveMoney size={52} color="#7d9417" />
              <h4>Ahorra dinero</h4>
              <p>
                Con los vehiculos eléctricos, los ahorros oscilan entre el 60 %
                y 70 % en combustible y mantenimiento, comparados con los
                vehículos tradicionales que utilizan gasolina.{" "}
              </p>
            </Col>
            <Col md={5} className="destacado">
              <GiEarthAmerica size={52} color="#7d9417" />
              <h4>Ayuda al planeta</h4>
              <p>
                Con el uso de un vehículo eléctrico se dejan de emitir
                aproximadamente 2,7 toneladas de CO2 al año; con un motociclo se
                dejan de emitir más o menos 1,7 toneladas de CO2 al año.
              </p>
            </Col>
          </Row>
          <Row className="sobre-destacados">
            <Col md={5} className="destacado">
              <GiStopSign size={52} color="#7d9417" />
              <h4>Adiós al pico y placa</h4>
              <p>
                Este tipo de vehiculos no tienen restricción de pico y placa, y
                tienen exenciones tributarias. Cada día se promueven nuevos
                beneficios para incentivar el uso de esta tecnología amigable
                con el ambiente y el bolsillo.
              </p>
            </Col>
            <Col md={5} className="destacado">
              <GiSoundWaves size={52} color="#7d9417" />
              <h4>Disminución de ruido</h4>
              <p>
                Los vehiculos eléctricos son increiblemente silenciosos,
                contribuyen a la eliminación de contaminación sonora en toda la
                ciudad.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default QuienesSomos;
