import React, { Component } from 'react'
import StackGrid from "react-stack-grid";
import Carousel, { Modal, ModalGateway } from "react-images";
import { connect } from 'react-redux';
import {constCongif} from '../../utils/Constants';

class Galeria extends Component {
    constructor( props ) {
        super( props );
        const { galerias, current } = this.props;
        this.state = {
          modalIsOpen:false,
          selectedIndex:0,
          galerias:galerias?galerias:[],
          galeria:[],
          currentGaleria:current?current:undefined,
        }
    }
    
    toggleModal = (id) => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen,selectedIndex:id?id:0 }));
      };
    
    sizeRand = () =>{
      const min = 0;
      const max = 4;
      const rand = Math.floor(min + Math.random() * (max - min));
      return rand
    }

  componentDidMount(){
      this.asignarEstado();
  }

  componentDidUpdate( prevProps ) {
      const { current } = this.props;
      if(current !== prevProps.current ){
          this.asignarEstado()
      }
  }

  asignarEstado(){
      const {current, galerias} = this.props;
      const gal = galerias.filter( item => item.id === current );
      this.setState( {
        galeria: gal.length ? gal[0].imagenes : [],
      } );
 
  }

    render() {
        const urlBase = constCongif.url.API_URL;
        const { modalIsOpen, selectedIndex, galeria } = this.state;
        const galeriaModal = [];
        galeria.map((item,i) => {
          const buket = item.filePath?.toString().split( 'assetsmep' ).length > 1 ? false : true;
          const imgPrincipal = buket ? urlBase + '/static/' + item.filePath : item.filePath;
          galeriaModal.push({
            src: `${imgPrincipal}`,
            key: i
          });
          return null
        });
        return (
            <div className="Galeria">
                <StackGrid
                  columnWidth={250}
                  monitorImagesLoaded={true}
                >
                  {galeria.map((item,i) => {
                    const buket = item.filePath?.toString().split( 'assetsmep' ).length > 1 ? false : true;
                    const imgPrincipal = buket ? urlBase + '/static/' + item.filePath : item.filePath;
                    return <div key={i} onClick={(e) => this.toggleModal(i)} className="galeriaBtn">
                              <img src={`${imgPrincipal}`} alt="Imagen" style={{maxWidth:"100%"}} />
                            </div>
                  }
                  )}
                  

                </StackGrid>
                <ModalGateway>
                    {modalIsOpen ? (
                    <Modal onClose={this.toggleModal}>
                        <Carousel views={galeriaModal} currentIndex={selectedIndex} />
                    </Modal>
                    ) : null}
                </ModalGateway>
            </div>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
  {
      settings,
      vehiculos,
  }
) )( Galeria  );