import { put, takeLatest } from "redux-saga/effects";
import { obtenerComparaciones } from '../api';

import { GET_COMPARACIONES_REQUEST, GET_COMPARACIONES_SUCCESS } from "../actions";

function* getComparaciones() {
    try {
        const res = yield obtenerComparaciones();
        yield put( { type: GET_COMPARACIONES_SUCCESS, payload: res.data.comparaciones } );
    } catch ( error ) {
        console.log(error);
    }
}

// Watcher
export default function* portafolioSaga() {
    yield takeLatest( GET_COMPARACIONES_REQUEST, getComparaciones );
}

