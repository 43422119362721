import React, { Component } from 'react'

/** BOOTSTRAP */
import { Container, Row, Col, Carousel, Form, Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom'
/*ASSETS */
import Banner from '../../assets/images/banner1.png';
/**Icons */
import { GiElectric } from 'react-icons/gi';
import { FaHeadset, FaGasPump } from 'react-icons/fa';
import parse from "html-react-parser";
import { constCongif } from '../../utils/Constants';
import { connect } from 'react-redux';
import { getEntradas as actionGetEntradas } from '../../redux/actions';
import moment from 'moment/moment';
var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));

class Blog extends Component {

    constructor( props ) {
        super( props );
        this.state = {
            filteredEntradas: [],
        }
    }

    componentDidMount() {
        this.props.getEntradas();
    }


    componentDidUpdate(prevProps) {
        const {
            entradas,
        } = this.props;

        if (entradas.entradas !== prevProps.entradas.entradas) {
            this.setState( { filteredEntradas : entradas.entradas });
        }
    }

    render() {
        const urlBase = constCongif.url.API_URL;
        const tags = this.props.entradas.entradas.map(entrada => entrada.tags).flat(1);
        return (
            <Container fluid className="sobreMeep">
                <Row className="banner position-relative">
                    <Col md={12} className="px-0">
                        <Carousel
                            easing="ease"
                            autoPlay={false}>
                            <Carousel.Item>
                                <div style={{ 'backgroundImage': `url(${urlBase}/static/assets/banner10.jpg)` }} className="bannerInterno"></div>
                            </Carousel.Item>
                            <Carousel.Item>
                                <div style={{ 'backgroundImage': `url(${urlBase}/static/assets/banner4.jpg)` }} className="bannerInterno"></div>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                    <Col md={{ span: 6, offset: 1 }} className="position-absolute">
                        <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
                        <h1>Muévete por el futuro</h1>
                    </Col>
                </Row>
                <Row className="pt-5 container mx-auto my-5">
                    <Col md={3} >
                        <Form className='mb-5'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" placeholder="Buscar" />
                            </Form.Group>
                        </Form>
                        <h5>Categorías</h5>
                        <div className="card mb-5">
                            <ul className="list-group list-group-flush">
                                {  
                                    tags.filter((element, index) => {
                                        return tags.indexOf(element) === index;
                                    }).map((item, i) => {
                                        return <li 
                                            className="list-group-item" 
                                            key={i}
                                            style={{ cursor: 'pointer'}}
                                            onClick={() =>  {
                                                this.setState( { filteredEntradas: this.state.filteredEntradas.filter(entrada => entrada.tags.includes(item)) } ) 
                                            }}
                                        >
                                            {item}
                                        </li>
                                    })
                                }
                                <li 
                                    className="list-group-item" 
                                    style={{ cursor: 'pointer'}}
                                    onClick={() =>  {
                                        this.setState( { filteredEntradas: this.props.entradas.entradas } ) 
                                    }}
                                >
                                    Todas
                                </li>
                            </ul>
                        </div>
                        <h5>Historias destacadas</h5>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                {
                                    this.props.entradas.entradas.map((item, i) => {
                                        return <li className="list-group-item" key={i}>
                                            <Link to={`/blog/post?id=${item._id}`}>
                                                <h3 className='d-inline'>{i + 1}</h3><span> {item.titulo.slice(0, 40)}...<br /><small style={{ color: "#707070" }}>{new Date(item.createdAt).toLocaleString("es-ES", options)}</small></span>
                                            </Link>
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </Col>
                    <Col md={7} >
                        <Row xs={1} md={2} className="g-4">
                            {this.state.filteredEntradas.map((entrada, i) => {
                                const imgPrincipal = entrada.imagenDestacada.length > 0 ? entrada.imagenDestacada[0].filePath : "https://www.bbva.ch/wp-content/uploads/2021/10/21-.-Ventajas-y-desventajas-de-la-movilidad-electrica-1024x493.png";
                                console.log(imgPrincipal)
                                const text = entrada.texto.replace(/(<([^>]+)>)/gi, "");
                                return <Col key={i}>
                                    <Card>
                                        <Link to={`/blog/post?id=${entrada._id}`}>
                                            <Card.Img
                                                variant="top"
                                                src={imgPrincipal}
                                            />
                                            <Card.Body>
                                                <small>{moment(entrada.createdAt).format('YYYY-MM-DD')}</small>
                                                <Card.Title>{entrada.titulo}</Card.Title>
                                                <Card.Text>
                                                    {text.slice(0, 250)}...
                                                </Card.Text>
                                            </Card.Body>
                                        </Link>
                                    </Card>

                                </Col>
                            })}
                            {/* {Array.from({ length: 4 }).map((_, idx) => (
                                <Col>
                                    <Card>
                                        <Link to={`/blog/post?id=${'hola'}`}>
                                        <Card.Img variant="top" src='https://www.bbva.ch/wp-content/uploads/2021/10/21-.-Ventajas-y-desventajas-de-la-movilidad-electrica-1024x493.png' />
                                        <Card.Body>
                                            <small>{event.toLocaleString("es-ES",options)}</small>
                                            <Card.Title>Card title</Card.Title>
                                            <Card.Text>
                                                This is a longer card with supporting text below as a natural
                                                lead-in to additional content. This content is a little bit
                                                longer.
                                            </Card.Text>
                                        </Card.Body>
                                        </Link>
                                    </Card>
                                    
                                </Col>
                            ))} */}
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect(({ entradas }) => (
    {
        entradas,
    }
), { getEntradas: actionGetEntradas })(Blog);
