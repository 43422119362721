import React, { Component } from 'react'
import {Link, withRouter} from 'react-router-dom'
import { connect } from 'react-redux';

/** BOOTSTRAP */
import {Container,Row,Col,Button} from 'react-bootstrap';

/** ASSETS */
import categoriImg from '../assets/images/categoriImg.png';
import vistaTarjetas from '../assets/images/vistaTarjetas.png';
import {constCongif} from '../utils/Constants';
import Config from '../utils/configuracion';

/* FONDO */
import {ReactComponent as GoldenCanonGrid} from '../assets/images/GoldenCanonGrid.svg'

class Categorias extends Component {
    constructor( props ) {
        super( props );
        
        const { vehiculos } = this.props;
        const url = this.props.location.search;
        const query = new URLSearchParams( url );
        const categoria = query.get( "cat" );
        this.state = {
            categoria: categoria,
            data: vehiculos.data ? vehiculos.data: [],
            nombreCat: Config[categoria].nombre, 
            entrada: Config[categoria].entrada,
            titulo: Config[categoria].titular,
            clase: Config[categoria].claseBase
        };
    }

    componentDidUpdate( prevProps ) {
        const { vehiculos } = this.props;
        if(vehiculos.data !== prevProps.vehiculos.data){
            this.setState( {
                data: vehiculos.data
            } );
        }
    }

    render() {      
        const {
            data,
            categoria,
            entrada,
            titulo,
            clase,
            nombreCat,
        } = this.state;  
        const urlBase = constCongif.url.API_URL;
        
        return (
            <Container className={`categoria ${clase}`} fluid>
                <Row className="position-absolute" md={12} style={{top: -175}}>
                    <GoldenCanonGrid className="fondoSVG"/>
                </Row>
                <Row>
                    <Col md={{ span: 5, offset: 1 }} >
                        <h4>{titulo}</h4>
                        <h1>{nombreCat}</h1> 
                    </Col>
                    {/* <Col md={6} className="vistaTarjetas" >
                        <div className='float-right'>
                            <img src={vistaTarjetas} alt="Vista de Tarjetas" className="d-block" />
                            <span>Vista de Tarjetas</span>
                        </div>
                    </Col> */}
                </Row>
                <Row>
                    {
                        data.filter( function(item){
                            const salida = item.categoria.filter( cat => cat.value === categoria );
                            return salida.length;
                        }).map((filteredItem,i) => {
                                const img = filteredItem.imagenes.principal;
                                let imgDat = {}; 
                                
                                img.forEach( item => {
                                    if( item.imagenes.length > 0 ){
                                        imgDat = item.imagenes[ 0 ];
                                    }
                                });
                                const filtroPrincipal = filteredItem.imagenes.principal.find( img => img.imagenes.length);
                                var imgPrincipal = filtroPrincipal?.imagenes[0]?.filePath;
                                const buket = imgPrincipal?.toString().split( 'assetsmep' ).length > 1 ? true : false;
                                imgPrincipal = imgPrincipal && ! buket ? urlBase + '/static/' + imgPrincipal : imgPrincipal;
                                return <Col md={3} className="categoria-producto" key={i}>
                                    <Link to={`/tienda/producto?id=${filteredItem._id}`}>
                                        <Container className="categoria-contenedor">
                                            <h3>{filteredItem.nombre}</h3>
                                            <img src={`${imgPrincipal}`} alt="Producto" />
                                            <h5>${filteredItem.precio.toLocaleString('es-ES', { style: 'currency', currency: 'COP',minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h5>
                                            <Button className="btnBase">+ INFO</Button>
                                        </Container>
                                    </Link>
                                </Col>
                        })
                    }
                </Row>
            </Container>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
    {
        settings,
        vehiculos,
    }
) )( withRouter( Categorias ) );
