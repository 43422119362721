import React from "react";
import { Link } from "react-router-dom";

/** BOOTSTRAP */
import { Container, Row, Col } from "react-bootstrap";

/*ASSETS */
import Bici from "../../assets/images/tiendaBici.png";
import Moto from "../../assets/images/tiendaMoto.png";
import Pati from "../../assets/images/tiendaPati.png";
import Casco from '../../assets/images/tiendaAcc2.png';
import Config from "../../utils/configuracion";

/**ANIMACIONES */
import { AnimarEntrada } from "../../components/Animaciones/AnimarEntrada";

const Tienda = () => {
  return (
    <Container fluid className="tienda">
      <Row>
        <Col md={3} className="motociclosClr tienda-categoria">
          <Link to="/tienda/categorias?cat=motocicletas">
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <span className="rotate">{Config.motocicletas.titular}</span>
            </AnimarEntrada>
            <AnimarEntrada timeout={700} tipoAnimacion="aparecer-izquierda">
              <img src={Moto} alt="Motocicletas" />
            </AnimarEntrada>
            <AnimarEntrada timeout={700} tipoAnimacion="aparecer">
              <Container className="tienda-contentInfo">
                <h4>{Config.motocicletas.entrada}</h4>
                <h1>Motociclos</h1>
                <h2>{Config.motocicletas.detalle}</h2>
              </Container>
            </AnimarEntrada>
          </Link>
        </Col>
        <Col md={3} className="BicicletasClr tienda-categoria px-0">
          <Link to="/tienda/categorias?cat=bicicletas">
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <span className="rotate">{Config.bicicletas.titular}</span>
            </AnimarEntrada>
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <img src={Bici} alt="Bicicletas" />
            </AnimarEntrada>
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <Container className="tienda-contentInfo">
                <h4>{Config.bicicletas.entrada}</h4>
                <h1>Bicicletas</h1>
                <h2>{Config.bicicletas.detalle}</h2>
              </Container>
            </AnimarEntrada>
          </Link>
        </Col>
        <Col md={3} className="PatinetasClr tienda-categoria">
          <Link to="/tienda/categorias?cat=patinetas">
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <span className="rotate">{Config.patinetas.titular}</span>
            </AnimarEntrada>
            <AnimarEntrada timeout={1000} tipoAnimacion="aparecer-derecha">
              <img src={Pati} alt="Patinetas" />
            </AnimarEntrada>
            <AnimarEntrada timeout={1000} tipoAnimacion="aparecer">
              <Container className="tienda-contentInfo">
                <h4>{Config.patinetas.entrada}</h4>
                <h1>Patinetas</h1>
                <h2>{Config.patinetas.detalle}</h2>
              </Container>
            </AnimarEntrada>
          </Link>
        </Col>
        <Col md={3} className="AccesoriosClr tienda-categoria">
          <Link to="/tienda/categorias?cat=accesorios">
            <AnimarEntrada timeout={1300} tipoAnimacion="aparecer">
              <span className="rotate">{Config.accesorios.titular}</span>
            </AnimarEntrada>
            <AnimarEntrada timeout={1000} tipoAnimacion="aparecer-derecha">
              <img src={Casco} alt="Accesorios" />
            </AnimarEntrada>
            <AnimarEntrada timeout={1000} tipoAnimacion="aparecer">
              <Container className="tienda-contentInfo">
                <h4>{Config.accesorios.entrada}</h4>
                <h1>Accesorios</h1>
                <h2>{Config.accesorios.detalle}</h2>
              </Container>
            </AnimarEntrada>
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Tienda;
