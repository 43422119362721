import React, { Component } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

/** BOOTSTRAP */
import { Container, Row, Col, Form, Button } from "react-bootstrap";

/**ASSETS */
import formularioImg from "../../assets/images/formularioImg.jpg";
import "react-datepicker/dist/react-datepicker.css";
import { createMensaje as actionCreateMensaje } from "../../redux/actions";
import { connect } from "react-redux";
registerLocale("es", es);

class TestDrive extends Component {
  state = {
    startDate: new Date(),
  };

  sendForm = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formObject = Object.fromEntries(data.entries());
    this.props.createMensaje({
      datos: { ...formObject, startDate: this.state.startDate },
      asunto: "Reserva",
      tipo: "reserva",
    });
  };

  handleChange = (date) => {
    this.setState({
      startDate: date,
    });
  };

  render() {
    return (
      <Container fluid className="Datos-Producto-TestDrive">
        <Row>
          <Col md={6} className="lateralTestDrive"></Col>
          <Col md={6}>
            <Container>
              <Row>
                <h2>Reserva tu Test Drive</h2>
                <Form
                  className={"formulario-TestDrive"}
                  onSubmit={this.sendForm}
                >
                  <div className="form-row">
                    <Form.Group as={Col} controlId="formGridName">
                      <Form.Label>Nombres</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese su nombre"
                        id="nombre"
                        name="nombre"
                      />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridLastName">
                      <Form.Label>Apellidos</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Ingrese sus apellidos"
                        id="apellidos"
                        name="apellidos"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridTel">
                      <Form.Label>Teléfono</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Móvil o fijo"
                        id="telefono"
                        name="telefono"
                      />
                    </Form.Group>
                  </div>

                  <div className="form-row">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="Email"
                        placeholder="Email válido"
                        id="email"
                        name="email"
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="formGridAddress">
                      <Form.Label>Dirección</Form.Label>
                      <Form.Control
                        placeholder="1234 Calle Principal"
                        id="direccion"
                        name="direccion"
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      controlId="formGridCity"
                      id="ciudad"
                      name="ciudad"
                    >
                      <Form.Label>Ciudad</Form.Label>
                      <Form.Control />
                    </Form.Group>
                  </div>
                  <div className="form-row">
                    <Form.Group as={Col} controlId="formGridDate">
                      <Form.Label>Fecha y hora de reserva</Form.Label>
                      <br />
                      <DatePicker
                        locale="es"
                        selected={this.state.startDate}
                        onChange={this.handleChange}
                        showTimeSelect
                        isClearable
                        minDate={new Date()}
                        className="form-control"
                        dateFormat="MMMM d, h:mm aa"
                      />
                    </Form.Group>
                  </div>
                  <div className="form-row">
                    <Form.Group as={Col} controlId="formGridDate">
                      <Button
                        variant="primary"
                        type="submit"
                        className="float-right"
                      >
                        Enviar
                      </Button>
                    </Form.Group>
                  </div>
                </Form>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(
  ({ settings }) => ({
    settings,
  }),
  { createMensaje: actionCreateMensaje }
)(TestDrive);
