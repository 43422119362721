import React from "react";
import { Link } from "react-router-dom";

/** BOOTSTRAP */
import { Container, Row, Col, Carousel } from "react-bootstrap";

/*ASSETS */
import Banner from "../../assets/images/banner1.png";

/**ANIMACIONES */
import { AnimarEntrada } from "../../components/Animaciones/AnimarEntrada";

/**Icons */
import { GiClick } from "react-icons/gi";
import { FaMoneyBill, FaCreditCard } from "react-icons/fa";
import { FiSmartphone } from "react-icons/fi";
import { RiArticleLine, RiBankFill } from "react-icons/ri";
import { constCongif } from "../../utils/Constants";

const Pagos = () => {
  const urlBase = constCongif.url.API_URL;
  const pse = `${urlBase}/static/assets/pse.png`;
  const davivienda = `${urlBase}/static/assets/davivienda.png`;
  const energia = `${urlBase}/static/assets/LogoEnergia.svg`;
  const alto = "52px";

  return (
    <Container fluid className="sobreMeep">
      <Row className="banner position-relative">
        <Col md={12} className="px-0">
          <Carousel easing="ease" autoPlay={false}>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner1.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner2.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="position-absolute">
          <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
          <h1>Muévete por el futuro</h1>
        </Col>
      </Row>
      <Row className="pt-5 container mx-auto">
        <Col md={3} className="px-md-4 text-center">
          <img src={energia} alt="PSE" style={{ maxHeight: alto }} />
          <h4 className="pt-3">Sistema de crédito Energía de Pereira</h4>
          <p>
            Desde Energía de Pereira realizaremos un estudio de crédito para que
            hagas el pago de tus cuotas con la factura de energía mes a mes.
            Plazo hasta de 36 meses con una tasa del 2.74 % mensual.
          </p>
        </Col>
        {/* <Col md={4} className="px-md-4 text-center">
          <img src={pse} alt="PSE" style={{ maxHeight: alto }} />
          <h4 className="pt-3">Pagos en línea - PSE </h4>
          <p>
            Paga directamente en nuestra página web, agrega tus productos al
            carrito de compras y págalos por PSE.
          </p>
        </Col> */}
        <Col md={3} className="px-md-4 text-center">
          <FaMoneyBill size={52} color="#7d9417" />
          <h4 className="pt-3">Pago en efectivo</h4>
          <p>
            Paga directamente en los puntos de pago de Energía de Pereira, solo
            debes acercarte con la factura de tu vehículo, entregada en nuestro
            punto de venta MEEP y listo.
          </p>
        </Col>
        <Col md={3} className="px-md-4 text-center">
          <FaCreditCard size={52} color="#7d9417" />
          <h4 className="pt-3">Datáfono</h4>
          <p>Pagos débito o crédito. Visa - Mastercard.</p>
        </Col>
        <Col md={3} className="px-md-4 text-center">
          <RiBankFill size={52} color="#7d9417" />
          <h4 className="pt-3">Consignaciones o transferencias </h4>
          <p>
            Realiza tu pago a las cuentas de Energía de Pereira y envíanos tu
            comprobante.
          </p>
        </Col>
      </Row>
      <Row className="pt-5 container mx-auto">
        {/* <Col md={4} className="px-md-4 text-center">
          <img src={davivienda} alt="PSE" style={{ maxHeight: alto }} />
          <h4 className="pt-3">App Davivienda Móvil</h4>
          <p>
            Aprueba en 5 minutos tu crédito con Davivienda desde la App y
            adquiere al instante tu vehículo eléctrico.
          </p>
        </Col> */}
        
      </Row>
    </Container>
  );
};

export default Pagos;
