import React, { Component } from 'react';
import { Route, Switch, withRouter, HashRouter } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { Provider, connect } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxSaga from "redux-saga";
import { Col, Row, Button, Form, Offcanvas, Toast } from "react-bootstrap";
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import RangeSlider from 'react-bootstrap-range-slider';
/* REDUX */
import reducers from './redux/reducers';
import rootSaga from './redux/sagas';
import {IoLogoWhatsapp} from 'react-icons/io';

import './App.scss';

/* COMPONENTES*/
import Pie from './components/pie'
import Navigation from './components/Navigation';
import Inicio from './components/Inicio';
import Tienda from './components/Tienda';
import Categorias from './components/Categorias';
import Producto from './components/Producto';
import QuienesSomos from './components/QuienesSomos';
import PuntosCarga from './components/PuntosCarga';
import Servicios from './components/Servicios';
import Contacto from './components/Contacto';
import Pagos from './components/Pagos';
import Blog from './components/Blog';
import Post from './components/Post';

/* BOOTSTRAP*/
import 'bootstrap/dist/css/bootstrap.min.css';

/*ACCIONES */
import { solicitarRegistros as actionSolicitarRegistros } from './redux/actions';

// CREAR MIDDLEWARE
const sagaMiddleWare = reduxSaga();

class PageWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      calculadoraStep: 1,
      vuelos: { europa: 0, nacionales: 0, eeuu: 0 },
      carro: { dias: 0, horas: 0 },
      bus: { dias: 0, horas: 0 },
      moto: { dias: 0, horas: 0 },
      co2: 0,
      nivel: "bajo",
      toastIsOpen: false
    }
    this.maybeCheckVehiculos = this.maybeCheckVehiculos.bind(this);
    this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
    this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.pasoCalculadora = this.pasoCalculadora.bind(this)
    this.toggleToast = this.toggleToast.bind(this)
  }

  componentDidMount() {
    this.maybeCheckVehiculos();
    this.maybeUpdateGlobalSettings();
  }

  componentDidUpdate(prevProps) {
    this.maybeCheckVehiculos(prevProps);
    this.maybeUpdateGlobalSettings(prevProps);
    this.maybeScrollPageToTop(prevProps);
  }

  maybeCheckVehiculos(prevProps) {
    const { vehiculos, solicitarRegistros } = this.props;
    if (!vehiculos.cargado && !vehiculos.cargando) {
      solicitarRegistros()
    }
  }

  maybeUpdateGlobalSettings(prevProps) {
    const { settings } = this.props;
  }

  maybeScrollPageToTop(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  toggleModal() {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen, calculadoraStep: 1 }));
  };

  toggleToast() {
    this.setState(state => ({ toastIsOpen: !state.toastIsOpen }));
  }

  pasoCalculadora() {
    let co2 = 0;
    let nivel = "bajo";
    if (this.state.calculadoraStep >= 5) {
      console.log("holi")
      const calculoVuelos = Object.values(this.state.vuelos).reduce((a, b) => a + b, 0);
      const calculoCarro = Object.values(this.state.carro).reduce((a, b) => a * b, 1);
      const calculoBus = Object.values(this.state.bus).reduce((a, b) => a * b, 1);
      const calculoMoto = Object.values(this.state.moto).reduce((a, b) => a * b, 1);
      co2 = calculoVuelos + calculoCarro + calculoBus + calculoMoto;
      if( co2 <= 3 ){ nivel="bajo"}else if(co2 <= 6){nivel="medio"}else{nivel="alto"}
    }
    this.setState(state => ({ calculadoraStep: this.state.calculadoraStep + 1, co2, nivel }));
  }

  render() {

    const indicador = (item) => {
      const principal = this.state[item.target.name];
      const valor = Number(item.target.value);
      principal[item.target.id] = valor;
      this.setState(state => ({ [principal]: principal }));
    }

    return (
      <div>
        <Navigation />
        <TransitionGroup className="row px-0 mx-0">
          <CSSTransition classNames="slide" timeout={1000}>
            <Switch >
              <Route path="/" exact component={Inicio} />
              <Route path="/tienda" exact component={Tienda} />
              <Route path="/tienda/categorias" component={Categorias} />
              <Route path="/tienda/producto" component={Producto} />
              <Route path="/sobremeep" exact component={QuienesSomos} />
              <Route path="/puntosdecarga" exact component={PuntosCarga} />
              <Route path="/servicios" exact component={Servicios} />
              <Route path="/contacto" exact component={Contacto} />
              <Route path="/medios-de-pago" exact component={Pagos} />
              <Route path="/blog" exact component={Blog} />
              <Route path="/blog/post" exact component={Post} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>

        <Pie />
        <div>
        <Toast show={this.state.toastIsOpen} onClose={this.toggleToast} className='toggleWhatsapp'>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded me-2"
              alt=""
            />
            <strong className="me-auto">Contactar con:</strong>
          </Toast.Header>
          <Toast.Body>
            <a href="https://api.whatsapp.com/send?phone=573138101290&text=Hola,%20informacion." target="_blank" rel="noopener noreferrer"><IoLogoWhatsapp /> Taller – 313 810 1290</a><br/>
            <a href="https://api.whatsapp.com/send?phone=573142642753&text=Hola,%20quiero%20informacion."  target="_blank" rel="noopener noreferrer"><IoLogoWhatsapp /> Carros - 314 264 2753</a><br/>
            <a href="https://api.whatsapp.com/send?phone=573133054528&text=Hola,%20quiero%20informacion."  target="_blank" rel="noopener noreferrer"><IoLogoWhatsapp /> Motos - 313 305 4528</a>
          </Toast.Body>
        </Toast>
          <div 
            onClick={this.toggleToast}
            //href="https://api.whatsapp.com/send?phone=57316 301 4346&text=Hola,%20quiero%20cotizar%20un%20producto."
            target="_blank"
            rel="noopener noreferrer" className="whatsappBtn">
            <div style={{ backgroundImage: "url(whatsapp-icon.png)" }}></div>
            ¡Habla con nosotros!
          </div>
          <Button onClick={this.toggleModal} className="huellaCarbono">
            ¿Cuál es el impacto<br />de tu transporte<br />en el medio ambiente?
          </Button>
        </div>
        <Offcanvas onHide={this.toggleModal} show={this.state.modalIsOpen} placement="bottom" style={{ height: "400px" }} className='calculadora'>
          <Offcanvas.Header closeButton >
            <Row className="w-100">
              <Col>
                <span><h4 className='d-inline'>1.</h4>Datos iniciales</span><br />
                <div>{
                  this.state.calculadoraStep <= 1 ?
                    <><IoIosCheckmarkCircleOutline /><div className='indicador'></div></> :
                    <><IoIosCheckmarkCircle className="circulo" /><div className='indicador chk'></div></>
                }
                </div>
              </Col>
              <Col>
                <span><h4 className='d-inline'>2.</h4>Vuelos</span>
                <div>{
                  this.state.calculadoraStep <= 2 ?
                    <><IoIosCheckmarkCircleOutline /><div className='indicador'></div></> :
                    <><IoIosCheckmarkCircle className="circulo" /><div className='indicador chk'></div></>
                }
                </div>
              </Col>
              <Col>
                <span><h4 className='d-inline'>3.</h4>Automovil</span>
                <div>{
                  this.state.calculadoraStep <= 3 ?
                    <><IoIosCheckmarkCircleOutline /><div className='indicador'></div></> :
                    <><IoIosCheckmarkCircle className="circulo" /><div className='indicador chk'></div></>
                }
                </div>
              </Col>
              <Col>
                <span><h4 className='d-inline'>4.</h4>Moto</span>
                <div>{
                  this.state.calculadoraStep <= 4 ?
                    <><IoIosCheckmarkCircleOutline /><div className='indicador'></div></> :
                    <><IoIosCheckmarkCircle className="circulo" /><div className='indicador chk'></div></>
                }
                </div>
              </Col>
              <Col>
                <span><h4 className='d-inline'>5.</h4>Transporte público</span>
                <div>{
                  this.state.calculadoraStep <= 5 ?
                    <><IoIosCheckmarkCircleOutline /><div className='indicador'></div></> :
                    <><IoIosCheckmarkCircle className="circulo" /><div className='indicador chk'></div></>
                }
                </div>
              </Col>
              <Col>
                <span><h4 className='d-inline'>6.</h4>Resultado</span>
                <div>{
                  this.state.calculadoraStep <= 6 ?
                    <><IoIosCheckmarkCircleOutline /></> :
                    <><IoIosCheckmarkCircle className="circulo" /></>
                }
                </div>
              </Col>
            </Row>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form className='container'>
              <div className='row'>
                <div className={`col-4 ${this.state.calculadoraStep === 1 ? "" : "d-none"}`}>

                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <h5>¿Cómo te llamas?</h5>
                    <Form.Control type="text" />
                  </Form.Group>

                </div>
                <div className={`col-4 row ${this.state.calculadoraStep === 2 ? "" : "d-none"}`}>
                  <h5>¿Cuántos vuelos nacionales tomaste el último año?</h5>
                  <div className='col'>
                    <RangeSlider
                      value={this.state.vuelos.nacionales}
                      min={0}
                      max={24}
                      id="nacionales"
                      name="vuelos"
                      onChange={e => indicador(e)}
                    /></div>
                  <h3 className='col'>{this.state.vuelos.nacionales}</h3>
                  <h5>¿Cuántos vuelos hacia Estados Unidos tomaste el último año?</h5>

                  <div className='col'><RangeSlider
                    value={this.state.vuelos.eeuu}
                    min={0}
                    max={24}
                    id="eeuu"
                    name="vuelos"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.vuelos.eeuu}</h3>
                  <h5>¿Cuántos vuelos hacia Europa tomaste el último año?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.vuelos.europa}
                    min={0}
                    max={24}
                    id="europa"
                    name="vuelos"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.vuelos.europa}</h3>
                </div>
                <div className={`col-4 row ${this.state.calculadoraStep === 3 ? "" : "d-none"}`}>
                  <h5>¿Cuántas horas gastas diariamente en un auto?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.carro.horas}
                    min={0}
                    max={24}
                    id="horas"
                    name="carro"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.carro.horas}</h3>
                  <h5>¿Cuántos días a la semana lo utilizas?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.carro.dias}
                    min={0}
                    max={24}
                    id="dias"
                    name="carro"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.carro.dias}</h3>
                  <Form.Select className="mb-3" aria-label="Default select example">
                    <option value="0">¿Qué combustible usa?</option>
                    <option value="1">Gasolina</option>
                    <option value="2">Gas natural</option>
                  </Form.Select>
                </div>
                <div className={`col-4 row ${this.state.calculadoraStep === 4 ? "" : "d-none"}`}>
                  <h5>¿Cuántas horas gastas diariamente en una moto?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.moto.horas}
                    min={0}
                    max={24}
                    id="horas"
                    name="moto"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.moto.horas}</h3>

                  <h5>¿Cuántos días a la semana la utilizas?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.moto.dias}
                    min={0}
                    max={7}
                    id="dias"
                    name="moto"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.moto.dias}</h3>

                </div>
                <div className={`col-4 row ${this.state.calculadoraStep === 5 ? "" : "d-none"}`}>
                  <h5>¿Cuántas horas gastas diariamente en un bus?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.bus.horas}
                    min={0}
                    max={24}
                    id="horas"
                    name="bus"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.bus.horas}</h3>

                  <h5>¿Cuántos días a la semana lo utilizas?</h5>
                  <div className='col'><RangeSlider
                    value={this.state.bus.dias}
                    min={0}
                    max={24}
                    id="dias"
                    name="bus"
                    onChange={e => indicador(e)}
                  /></div>
                  <h3 className='col'>{this.state.bus.dias}</h3>

                </div>
                <div className={`col-12 row ${this.state.calculadoraStep === 6 ? "" : "d-none"}`}>
                  <div className='col-2'>
                    <h4 className='nivel'>Emites</h4>
                    <h2 style={{display:"inline-block"}}>{this.state.co2}</h2><h5 style={{display:"inline-block"}}>t CO2</h5>
                  </div>
                  <div className='col-4'>
                    <span>El rango por año está en un aproximado de 6 – 9 toneladas de CO2 por Vehículo (cabe resaltar que depende mucho también del tipo de carro, moto, modelo y kilometraje recorrido)</span><br /><br />
                    <h5>Rangos de emisiones</h5>
                    <span><b className={ this.state.nivel === "bajo" ? "nivel" : ""}>Emisión mínima CO2:</b> 1-3 toneladas por año<br />
                      <b className={ this.state.nivel === "medio" ? "nivel" : ""}>Emisión media CO2:</b> 4-6 toneladas por año<br />
                      <b className={ this.state.nivel === "alto" ? "nivel" : ""}>Emisión máxima CO2:</b> 7-9 toneladas por año<br />
                    </span>
                  </div>
                  <div className='col-6'>
                    {
                      {
                        'bajo': <br />,
                        'medio': <br />,
                        'alto': <br />
                      }[this.state.nivel]
                    }
                  </div>
                </div>
                <div className={`col-6 ${this.state.calculadoraStep < 6 ? "" : "d-none"}`} style={{ position: "absolute", bottom: "20px" }}><Button onClick={this.pasoCalculadora}>Siguiente</Button></div>
              </div>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

    );
  }
}
const PageWrapWithState = connect(({ settings, vehiculos, user }) => (
  {
    vehiculos,
    settings,
    user,
  }
), { solicitarRegistros: actionSolicitarRegistros })(withRouter(PageWrap));

/**
 * Component App
 */
class App extends Component {
  constructor(props) {
    super(props);

    if (process.env.NODE_ENV === 'development') {
      // DESARROLLO USAMOS UNOS TOOLS PARA DEBUGEAR EN CHROME Y MOZZILA
      const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
      this.store = createStore(reducers, compose(applyMiddleware(sagaMiddleWare), reduxDevTools));
    } else {
      this.store = createStore(reducers, compose(applyMiddleware(sagaMiddleWare)));
    }

    sagaMiddleWare.run(rootSaga);
  }

  render() {
    return (
      <Provider store={this.store}>
        <HashRouter>
          <PageWrapWithState />
        </HashRouter>
      </Provider>
    );
  }
}

export default App;