import React from "react";

/** BOOTSTRAP */
import { Container, Row, Col, Carousel } from "react-bootstrap";

/**Icons */
import { GiElectric, GiBackup } from "react-icons/gi";
import { FaHeadset, FaGasPump } from "react-icons/fa";
import { constCongif } from "../../utils/Constants";

const Servicios = () => {
  const urlBase = constCongif.url.API_URL;
  return (
    <Container fluid className="sobreMeep">
      <Row className="banner position-relative">
        <Col md={12} className="px-0">
          <Carousel easing="ease" autoPlay={false}>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner10.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner4.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="position-absolute">
          <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
          <h1>Muévete por el futuro</h1>
        </Col>
      </Row>
      <Row className="pt-5 container mx-auto">
      <Col md={3} className="px-md-4 text-center">
          <GiBackup size={52} color="#7d9417" />
          <h4 className="pt-3">Comercialización y asesoría personalizada</h4>
          <p>
          Por medio de nuestros canales de atención recibirás una asesoría personalizada para que el producto que elijas sea el que más se adecúe a tus necesidades. Con más de 500 vehículos vendidos en nuestra región y más de 5 años de trayectoria en el mercadeo, podemos asesorarte partiendo de nuestra experiencia, somos distribuidores autorizados de marcas como AKT electric, NIU, Vsett y Ninebot.
          </p>
        </Col>
        <Col md={3} className="px-md-4 text-center">
          <FaHeadset size={52} color="#7d9417" />
          <h4 className="pt-3">Servicio de Postventa</h4>
          <p>
          Nuestro servicio postventa cuenta con el primer taller especializado en movilidad eléctrica en la región, nuestro equipo técnico capacitado te podrá brindar soporte para que sientas tranquilidad desde el momento en el que adquieres tu vehículo eléctrico, sabemos que es una tecnología nueva, es por esto que estamos a tu disposición para solucionar las dudas que te surjan.
          </p>
          <p>
          Ofrecemos servicios como atención de garantías, mantenimientos preventivos o correctivos, todo bajo el respaldo de la Empresa de Energía de Pereira.
          </p>
        </Col>
        <Col md={3} className="px-md-4 text-center">
          <GiElectric size={52} color="#7d9417" />
          <h4 className="pt-3">Venta de puntos de carga</h4>
          <p>
          Ofrecemos el servicio de venta de puntos de carga para vehículos eléctricos livianos y pesados, contamos con el personal y los equipos para comercializar este tipo de estaciones. Ponte en contacto con nosotros y realizaremos una visita para ofrecerte una cotización.</p>
        </Col>
        <Col md={3} className="px-md-4 text-center">
          <FaGasPump size={52} color="#7d9417" />
          <h4 className="pt-3">Instalación de puntos de carga</h4>
          <p>
          ¿Adquiriste carro eléctrico y no sabes quién puede instalarte el cargador?, nosotros prestamos ese servicio, nuestras cuadrillas de trabajo están a tu disposición para realizar dicha instalación siguiendo la normatividad vigente, ¿quieres que tus clientes tengan acceso a un punto de carga para vehículos eléctricos? te acompañamos en este proyecto que brindará un gran valor agregado a tu marca y ayudará a estar a la vanguardia con la movilidad del futuro.
          </p>
        </Col>
        
        
      </Row>
    </Container>
  );
};

export default Servicios;
