import React from 'react'
import {TransitionGroup,CSSTransition} from 'react-transition-group'

export const AnimarEntrada = ({key,timeout,children,tipoAnimacion})=>(
    <TransitionGroup>
        <CSSTransition
            classNames={tipoAnimacion}
            timeout={timeout}
            key={key}
            appear={true}>
            {children}
        </CSSTransition>
    </TransitionGroup>
)