import React, { Component } from 'react'

/** BOOTSTRAP */
import { Container, Row, Col, Carousel as BootstrapCarousel, Form, ListGroup } from 'react-bootstrap';

/**Icons */
import { constCongif } from '../../utils/Constants';
import { Link, withRouter } from 'react-router-dom'
import parse from "html-react-parser";
import { getEntrada as actionGetEntrada, getEntradas as actionGetEntradas } from '../../redux/actions';
import { connect } from 'react-redux';
import StackGrid from "react-stack-grid";
import moment from 'moment';
import 'moment/locale/es';
import Carousel, {Modal, ModalGateway } from "react-images";


var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
const event = new Date(Date.UTC(2012, 11, 20, 3, 0, 0));

class Post extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentGaleria: undefined,
            modalIsOpen: false, 
            selectedIndex: 0
        }
    }
    componentDidMount() {
        const {
            getEntrada,
        } = this.props;

        const url = this.props.location.search;
        const query = new URLSearchParams(url);
        const itemId = query.get("id");
        this.props.getEntradas();
        if (itemId) {
            getEntrada(itemId);
        }
    }

    toggleModal = (id) => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen, selectedIndex: id ? id : 0 }));
    };

    render() {
        const urlBase = constCongif.url.API_URL;
        const { modalIsOpen, selectedIndex,  } = this.state;
        const { entrada } = this.props.entradas;
        const imgPrincipal = entrada.imagenDestacada?.length > 0 ? entrada.imagenDestacada[0].filePath : "https://www.bbva.ch/wp-content/uploads/2021/10/21-.-Ventajas-y-desventajas-de-la-movilidad-electrica-1024x493.png";
        const galeriaModal = [];
        entrada.galeria?.map((item,i) => {
          galeriaModal.push({
            src: item.filePath,
            key: i
          });
          return null
        });

        return (
            <Container fluid className="sobreMeep">
                <Row className="banner position-relative">
                    <Col md={12} className="px-0 py-2">
                        <BootstrapCarousel
                            easing="ease"
                            autoPlay={false}>
                            <BootstrapCarousel.Item>
                                <div style={{ 'backgroundImage': `url(${urlBase}/static/assets/banner10.jpg)` }} className="bannerInterno"></div>
                            </BootstrapCarousel.Item>
                            <BootstrapCarousel.Item>
                                <div style={{ 'backgroundImage': `url(${urlBase}/static/assets/banner4.jpg)` }} className="bannerInterno"></div>
                            </BootstrapCarousel.Item>
                        </BootstrapCarousel>
                    </Col>
                    <Col md={{ span: 6, offset: 1 }} className="position-absolute">
                        <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
                        <h1>Muévete por el futuro</h1>
                    </Col>
                </Row>
                <Row className="pt-5 container mx-auto my-5">
                    <Col md={9} className="px-5 position-relative">
                        <img src={imgPrincipal} style={{ width: "100%" }} alt="Titulo" />
                        <div className='position-relative p-4' style={{ background: "white", top: "-7%", margin: "0 3%" }}>
                            <h5>{new Date(entrada.createdAt).toLocaleString("es-ES", options)}</h5>
                            <h2> {entrada.titulo} </h2>
                            {entrada.texto ? parse(entrada.texto) : ''}
                            <div className='position-relative pb-2'>
                                <StackGrid
                                    columnWidth={250}
                                    monitorImagesLoaded={true}
                                >
                                    {entrada.galeria ? entrada.galeria.map((item, i) => {
                                        console.log(item)
                                        return <div key={i} onClick={(e) => this.toggleModal(i)} className="galeriaBtn">
                                            <img src={item.filePath} alt="Imagen" style={{ maxWidth: "100%" }} />
                                        </div>
                                    }
                                    ) : null}


                                </StackGrid>
                                <ModalGateway>
                                    {modalIsOpen ? (
                                        <Modal onClose={this.toggleModal}>
                                            <Carousel views={galeriaModal} currentIndex={selectedIndex} />
                                        </Modal>
                                    ) : null}
                                </ModalGateway>
                            </div>
                            {
                                entrada.tags?.length > 0 ?
                                    <ListGroup horizontal style={{ fontSize: 10 }}>
                                        {entrada.tags.map((item, j) => {
                                            return <ListGroup.Item key={j}>{item}</ListGroup.Item>
                                        })}
                                    </ListGroup>
                                    : null
                            }

                        </div>


                    </Col>
                    <Col md={3}>
                        <Form className='mb-5'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="text" placeholder="Buscar" />
                            </Form.Group>
                        </Form>
                        {/* <h5>Categorías</h5>
                        <div className="card mb-5">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">Cras justo odio</li>
                                <li className="list-group-item">Dapibus ac facilisis in</li>
                                <li className="list-group-item">Vestibulum at eros</li>
                            </ul>
                        </div> */}
                        <h5>Historias destacadas</h5>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                {
                                    this.props.entradas.entradas.map((item, i) => {
                                        return <li className="list-group-item" key={i}>
                                            <Link to={`/blog/post?id=${item._id}`}>
                                                <h3 className='d-inline'>{i + 1}</h3><span> {item.titulo.slice(0, 40)}...<br /><small style={{ color: "#707070" }}>{new Date(item.createdAt).toLocaleString("es-ES", options)}</small></span>
                                            </Link>
                                        </li>
                                    })
                                }

                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect(({ entradas }) => (
    {
        entradas,
    }
), { getEntrada: actionGetEntrada, getEntradas: actionGetEntradas })(Post);