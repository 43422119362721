import React, { Component } from 'react';
import {Link,Route, BrowserRouter as RouterMenu,Switch, withRouter} from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { connect } from 'react-redux';

/* COMPONENTES*/
import Detalles from '../components/Datos-Producto/Detalles';
import Galeria from '../components/Datos-Producto/Galeria';
import Especificaciones from '../components/Datos-Producto/Especificaciones';
import TestDrive from '../components/Datos-Producto/TestDrive';
import Credito from '../components/Simuladores/Credito';
/**ANIMACIONES */
import {AnimarEntrada} from '../components/Animaciones/AnimarEntrada';

/** BOOTSTRAP */
import {Container,Row,Col,Image,Button,Tabs,Tab} from 'react-bootstrap';

/** ASSETS */
import {constCongif} from '../utils/Constants';

/* FONDO */
import {ReactComponent as GoldenCanonGrid} from '../assets/images/GoldenCanonGrid.svg';

/*ACCIONES */
import { currentRegistro as actionCurrentRegistro } from '../redux/actions';

const urlBase = constCongif.url.API_URL;

const AnimatedSwitch = withRouter(({ location }) => (
    <TransitionGroup className="container-fluid">
      <CSSTransition key={location.key} classNames="slide" timeout={1000}>
        <Switch location={location}>
            <Route path="/tienda/producto/detalles/"  component={Detalles}/>
            <Route path="/tienda/producto/galeria/"  component={Galeria}/>
            <Route path="/tienda/producto/especificaciones/"  component={Especificaciones}/>
            <Route path="/tienda/producto/testdrive/"  component={TestDrive}/>  
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  ));


class Producto extends Component {
    constructor( props ) {
        super( props );
        const { vehiculos } = this.props;
        this.state = {
            isBoxVisible:false,
            inProp:false,
            datosCargados:false,
            nombre:  "", 
            id:"",
            referencia: "",
            categoria:[],
            precio:"",
            marca:[],
            titular: {
                    superior:"",
                    principal:"",
                    inferior:""
            },
            descripcion:"",
            galeria: [],
            principal:[{filePath:""}],
            logo: [{filePath:""}],
            detalles:[],
            destacados:[{dato:"",valor:""},{dato:"",valor:""},{dato:"",valor:""}],
            currentGaleria:undefined,
            activeTab:""
        }
    }
    
    componentDidMount(){
        if(!this.state.datosCargados){
            this.asignarEstado();
        }
    }

    componentDidUpdate( prevProps ) {
        const { vehiculos } = this.props;
        if(vehiculos.data !== prevProps.vehiculos.data){
            this.asignarEstado()
        }
    }

    asignarEstado(){
        const { vehiculos,currentRegistro } = this.props;
        const url = this.props.location.search;
        const query = new URLSearchParams( url );
        const idProducto = query.get( "id" );
        let filtro = vehiculos.data.filter( item => item._id === idProducto );

        
        if(filtro[0]){
            const vehiculo = filtro[0];
            let imgLogo = vehiculo.imagenes.logo.length ? vehiculo.imagenes.logo[0].filePath : null;
            
            const buket = imgLogo?.toString().split( 'assetsmep' ).length > 1 ? true : false;
            imgLogo = imgLogo && ! buket ? urlBase + '/static' + imgLogo : imgLogo;
            console.log("imgLogo",imgLogo)
            
            currentRegistro(vehiculo);
            this.setState( {
                datosCargados:true,
                nombre: vehiculo.nombre ? vehiculo.nombre : "", 
                id:idProducto,
                referencia:vehiculo.referencia ? vehiculo.referencia : "",
                categoria:vehiculo.categoria ? vehiculo.categoria: [],
                precio:vehiculo.precio ? vehiculo.precio: "",
                marca:vehiculo.marca ? vehiculo.marca : [],
                titular:vehiculo.titular ? vehiculo.titular: {
                    superior:"Lorem ipsum dolor sit amet",
                    principal:"LOREM IPSUM CONSECTETUR",
                    inferior:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris"
                },
                descripcion:vehiculo.descripcion ? vehiculo.descripcion: "",
                galeria: vehiculo.imagenes.galeria ? vehiculo.imagenes.galeria: [],
                principal: vehiculo.imagenes.principal.length ? vehiculo.imagenes.principal: [{filePath:""}],
                logo: imgLogo,
                detalles:vehiculo.detalles ? vehiculo.detalles: [],
                destacados:vehiculo.destacados ? vehiculo.destacados: [],
                currentGaleria:  vehiculo.imagenes.galeria? vehiculo.imagenes.galeria[0].id : undefined, 
            } );
        }
        
    }

    activar = () => {
        this.setState(prevState => ({ isBoxVisible: !prevState.isBoxVisible }));
    };

    _handleClick(menuItem) { 
        this.setState({ active: menuItem });
      }

    render() {
        const { 
            referencia,
            categoria,
            nombre, 
            precio,
            marca,
            titular,
            descripcion,
            galeria,
            principal,
            logo,
            detalles,
            destacados,
            currentGaleria,
            activeTab
         } = this.state;
        const urlBase = constCongif.url.API_URL;
        const filtroPrincipal = principal.filter(item => item.galeria === currentGaleria)[0].imagenes;
        var imgPrincipal = filtroPrincipal ? filtroPrincipal[0]?.filePath : "";
        const buket = imgPrincipal?.toString().split( 'assetsmep' ).length > 1 ? true : false;
        imgPrincipal = imgPrincipal && ! buket ? urlBase + '/static/' + imgPrincipal : imgPrincipal;
        return (
            <Container className="producto" fluid>
                <Row className="position-absolute" md={12} style={{top: -175, zIndex: -10}}>
                    <GoldenCanonGrid className="fondoSVG"/>
                </Row>
                <Row>
                    <Container>
                        <Row>
                            <Col md={2}>
                                { logo ? <AnimarEntrada timeout={200}  tipoAnimacion="aparecer" >
                                    <img src={`${logo}`} alt={nombre} className="producto-label"/>
                                </AnimarEntrada> : null}
                                
                            </Col>
                            <Col md={5}>
                                <AnimarEntrada timeout={1000}  tipoAnimacion="aparecer" >
                                    <img src={`${imgPrincipal}`} alt={nombre} className="producto-img" />
                                </AnimarEntrada>
                            </Col>
                            <Col md={5}>
                                <AnimarEntrada timeout={500} tipoAnimacion="aparecer-derecha" >
                                    <div className="tituloBase">
                                        <span>{titular.superior}</span>
                                        <h1>{nombre}</h1> 
                                        <h2>{titular.principal}</h2>
                                        <span className="infoText">{titular.inferior}</span>
                                        <h4>Colores: </h4>
                                        <div className="row container pt-1">
                                            {
                                                galeria.map( ( item ) =>{
                                                    const color = item.color ? `rgba(${item.color.r}, ${item.color.g}, ${item.color.b}, ${item.color.a})`: "rgba(255,255,255,1)";
                                                    return <div key={item.id} className="rounded-circle colorSelector mx-1" style={{backgroundColor:color}} onClick={ (e) => this.setState({currentGaleria:item.id, activeTab:"galeria"})} />
                                                })
                                            }
                                        </div>
                                        <h4>Precio: </h4>
                                        <h3>${precio.toLocaleString('es-ES', { style: 'currency', currency: 'COP',minimumFractionDigits: 0, maximumFractionDigits: 0 })}</h3>
                                        <Button className="btnBase float-right">Comprar</Button>
                                    </div>
                                </AnimarEntrada>
                            </Col>
                        </Row>
                    </Container>
                </Row>
                <Row>
                    <Col md={6} className="detalles-destacados">
                        <AnimarEntrada timeout={1500}  tipoAnimacion="aparecer-abajo" >
                            <Row>
                                <Col md={4}>
                                    <h3>{destacados[0].valor}</h3>
                                    <h6>{destacados[0].dato && destacados[0].dato.label ? destacados[0].dato.label: ""}</h6>
                                </Col>
                                <Col md={4} className="mitad">
                                    <h3>{destacados[1].valor}</h3>
                                    <h6>{destacados[1].dato && destacados[1].dato.label ? destacados[1].dato.label: ""}</h6>
                                </Col>
                                <Col md={4}>
                                    <h3>{destacados[2].valor}</h3>
                                    <h6>{destacados[2].dato && destacados[2].dato.label ? destacados[2].dato.label: ""}</h6>
                                </Col>
                            </Row>
                        </AnimarEntrada>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <RouterMenu>
                        <Row>
                            <Col md={12}>
                                <Tabs 
                                    defaultActiveKey="detalles" id="uncontrolled-tab-example" className="menu-productos" 
                                    activeKey={activeTab} onSelect={(k) => this.setState({activeTab:k})}
                                >
                                    {
                                    detalles.length > 0 ? 
                                    <Tab eventKey="detalles" title="Detalles">
                                        <Detalles />
                                    </Tab>
                                    :null
                                    }
                                    <Tab eventKey="galeria" title="Galería">
                                        <Galeria galerias={galeria} current={currentGaleria} />
                                    </Tab>
                                    <Tab eventKey="especificaciones" title="Especificaciones">
                                        <Especificaciones />
                                    </Tab>
                                    {/* <Tab eventKey="simularCredito" title="Simulación de crédito">
                                        <Credito />
                                    </Tab> */}
                                    <Tab eventKey="testDrive" title="Test Drive">
                                        <TestDrive />
                                    </Tab>
                                </Tabs>
                                
                                {/* <Nav className="flex-nowrap menu-productos">
                                    { menuDatos.map( dato=>(
                                        <Link key={ dato.name }
                                            name={ dato.name }
                                            className={`nav-link ${this.state.active === dato.name ? "activo" : ""}`} 
                                            onClick={this._handleClick.bind(this, dato.name)}
                                            to={"/tienda/producto/"+dato.destino}
                                        >{ dato.name }</Link>
                                        ))
                                    }
                                </Nav> */}
                            </Col>
                        </Row>
                        <Row>
                            <AnimatedSwitch/>
                        </Row>
                        </RouterMenu>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
    {
        settings,
        vehiculos,
    }
),{currentRegistro: actionCurrentRegistro} )( withRouter( Producto ) );