import React, { Component } from 'react'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

/** BOOTSTRAP */
import {Container,Row,Col} from 'react-bootstrap';

/**ASEETS */
import imgDetalle from '../../assets/images/imgDetalle.png'
import {constCongif} from '../../utils/Constants';

/**Icons */
import {FcEngineering} from 'react-icons/fc';
import {IoIosSpeedometer} from 'react-icons/io';

/**ANIMACIONES */
import {AnimarEntrada} from '../../components/Animaciones/AnimarEntrada';

/*ACCIONES */
import { currentRegistro as actionCurrentRegistro } from '../../redux/actions';

class Detalles extends Component {
    constructor( props ) {
        super( props );
        const { vehiculos } = this.props;
        this.state = {
            detalles:[],
        }
    }

    componentDidMount(){
        this.asignarEstado();
    }

    componentDidUpdate( prevProps ) {
        const { vehiculos } = this.props;
        if(vehiculos.data !== prevProps.vehiculos.data){
            this.asignarEstado()
        }
    }

    asignarEstado(){
        const {vehiculos} = this.props;
        this.setState( {
            detalles:vehiculos.current.detalles ? vehiculos.current.detalles : [],
        } );
        
        
    }
    
    render() {
        const {detalles} = this.state;
        const urlBase = constCongif.url.API_URL;
        const ParItem = (data) =>{
            const item = data.data;
            const imagen = item.imagen[0].filePath ? item.imagen[0].filePath:imgDetalle;
            return <Row >
            <Col md={4}>
                <AnimarEntrada timeout={500}  tipoAnimacion="aparecer" >
                    <div className="w-100 imagenDetalle" style={{backgroundImage:`url(${urlBase}/static${imagen})`}}></div>
                </AnimarEntrada>
            </Col>
            <Col md={4} className="detalle">
                <AnimarEntrada timeout={700}  tipoAnimacion="aparecer-abajo" >
                    <div className="tituloBase">
                        <span>{item.tituloSuperior}</span>
                        <h1>{item.tituloPrincipal}</h1> 
                        <h2>{item.tituloSecundario}</h2>  
                        <span>{item.descripcion}</span>
                    </div>
                </AnimarEntrada>
            </Col>
            <Col md={4} className="iconos detalle">
                <AnimarEntrada timeout={1000}  tipoAnimacion="aparecer-derecha" >
                    <Row>
                        <Col>
                            <FcEngineering size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                        <Col>
                            <FcEngineering size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                        <Col>
                            <FcEngineering size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                    
                    </Row>
                </AnimarEntrada>
            </Col>
        </Row>
        }

        const InParItem = (data) =>{
            const item = data.data;
            const imagen = item.imagen[0].filePath ? item.imagen[0].filePath:imgDetalle;
            return <Row>
            <Col md={4} className="iconos detalle">
                <AnimarEntrada timeout={1500}  tipoAnimacion="aparecer-izquierda" >
                    <Row>
                        <Col>
                            <FcEngineering size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                        <Col>
                            <IoIosSpeedometer size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                        <Col>
                            <FcEngineering size={52}/>
                            <h6>Velocidad</h6>
                        </Col>
                    </Row>
                </AnimarEntrada>
            </Col>
            <Col md={4} className="detalle">
                <AnimarEntrada timeout={1700}  tipoAnimacion="aparecer-abajo" >
                    <div className="tituloBase">
                        <span>{item.tituloSuperior}</span>
                        <h1>{item.tituloPrincipal}</h1> 
                        <h2>{item.tituloSecundario}</h2>  
                        <span>{item.descripcion}</span>
                    </div>
                </AnimarEntrada>
            </Col>
            <Col md={4} className="h-100" >
                <AnimarEntrada timeout={2000}  tipoAnimacion="aparecer-derecha" >
                    <div className="w-100 imagenDetalle" style={{backgroundImage:`url(${urlBase}/static${imagen})`}}></div>
                </AnimarEntrada>
            </Col>
        </Row>
        }
        return (
            <Container fluid className="Datos-Producto-Detalles">
                {detalles.map((item,i)=>{
                    if ( i % 2 === 0) {
                        return <ParItem data={item} key={i}/>
                    }else{
                        return <InParItem data={item} key={i}/>
                    }
                    
                    
                })}
            </Container>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
    {
        settings,
        vehiculos,
    }
),{currentRegistro: actionCurrentRegistro} )( withRouter( Detalles ) );