import React, { Component } from 'react';
import {Link,withRouter} from 'react-router-dom';
import { connect } from 'react-redux';

/*BOOTSTRAP*/
import {Navbar,Nav,Container, NavDropdown} from 'react-bootstrap';
import {constCongif} from '../utils/Constants';

class Navigation extends Component {
    constructor( props ) {
        super( props );
        this.state = {
            className:"",
            seccion:this.props.location.pathname
        }

    }
    componentDidMount(){
        window.addEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps){
        const {location} = this.props;

        if(location !== prevProps.location){
            this.setState({
                seccion:location.pathname
            })
        }

    }
    
    handleScroll=()=>{
        if (window.pageYOffset > 100) {
            if(!this.state.className){
            this.setState({ className: "degradar" });   
            }
        }else{
            if(this.state.className){
            this.setState({ className: "" });
            }
        }
    
    }

    render() {
        const urlBase = constCongif.url.API_URL;
        const {className, seccion} = this.state;
        return (
            <>
                <Navbar expand="lg" ref={(r)=>this.ref=r} className={`${className} ${seccion === '/' ? "initBar navbar-dark":""}`} id="contMenu" >
                <Navbar.Brand href="/"><img src={`${urlBase}/static/assets/logo.svg`} alt="MEEP" style={{height:"80px"}} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="text-center">
                        <Nav className="ml-auto flex-nowrap">
                            <Link to="/" className="nav-link">Inicio</Link>
                            <Link to="/tienda/"  className="nav-link">Productos</Link>
                            <Link to="/sobremeep/" className="nav-link">Sobre MEEP</Link>
                            <Link to="/blog/" className="nav-link">Blog</Link>
                            <Link to="/puntosdecarga/" className="nav-link">Puntos de Carga</Link>
                            <Link to="/servicios/" className="nav-link">Servicios</Link>
                            <Link to="/medios-de-pago/" className="nav-link">Medios de pago</Link>
                            <Link to="/contacto/" className="nav-link">Contacto</Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
    }
}

export default connect( ( { settings } ) => (
    {
        settings
    }
) )( withRouter( Navigation ) );