import React, { Component } from 'react'
import { connect } from 'react-redux';

/** BOOTSTRAP */
import {
    Container,
    Row,
    Col, 
    Card, 
    Form,
    Button, 
    ButtonGroup, 
    OverlayTrigger,
    Tooltip, 
    ToggleButton,
    Tabs,
    Tab} from 'react-bootstrap';

/*ASSETS */
import Banner from '../../assets/images/banner1.png';
import RangeSlider from 'react-bootstrap-range-slider';


/**Icons */
import {FaGasPump, FaBicycle, FaMotorcycle, FaCar, FaBus, FaPercentage} from 'react-icons/fa';
import {FcElectricity} from 'react-icons/fc';
import {AiOutlineDollar} from 'react-icons/ai';
import {constCongif} from '../../utils/Constants';

class Credito extends Component {
    constructor( props ) {
        super( props );
        const { vehiculos } = this.props;
        this.state = {
            data: vehiculos.data?vehiculos.data:[],
            option1 :false,
            option2:false,
            interes: 1.90,
            cuotaInicial:0,
            meses:12,
            calculado:false
        };
    }

    getPrecio(itemId){
        const {data} = this.state;
        const precio = data.filter(item => itemId === item._id).map(vehiculo =>{
            return Number(vehiculo.precio);
        })
        return precio.length ? precio[0] : 0;
    }

    componentDidUpdate( prevProps ) {
        const { vehiculos } = this.props;
        if(vehiculos.data !== prevProps.vehiculos.data){
            this.setState( {
                data: vehiculos.data
            } );
        }
    }

    handleChange( e ){
        const value = e.currentTarget.value;
        const nombre = e.currentTarget.name;
        this.setState({
           [nombre]:value 
        }) 
    }

    calcular( e ){
        e.preventDefault();
        this.setState({
            calculado:true
        })
    }

    getValorDeCuotaFija(monto, cuotas) {
        const tasa = this.state.interes/100;
        const valor = monto *( (tasa * Math.pow(1 + tasa, cuotas)) / (Math.pow(1 + tasa, cuotas) - 1) );
        return Math.round(valor);
    }
    
    
    render() {
        const{
            cuotaInicial,
            meses,
            data,
            interes,
            option1,
            option2,
            calculado
        } = this.state;
        
        const urlBase = constCongif.url.API_URL;

        const TablaAmortizacion = () =>{
            const monto = Number(vlrVehiculo-cuotaInicial)
            const cuota = this.getValorDeCuotaFija(monto, meses);
            let saldo = monto;
            return [...Array((Number(meses)+1))].map( (item, i)=>{
                let interesMes = 0;
                let abonoCapital =  0;
                let nuevoSaldo = Math.round(saldo);
                let cuotaPeriodo=0;
                if(i===0){
                    interesMes = 0;
                    abonoCapital =  0;
                    nuevoSaldo = Math.round(saldo);
                    saldo = nuevoSaldo - abonoCapital;
                }else{
                    interesMes = Math.round((interes/100)*saldo);
                    abonoCapital =  cuota-interesMes;
                    nuevoSaldo = saldo;
                    cuotaPeriodo = cuota;
                    saldo = nuevoSaldo - abonoCapital;
                }                
                return  <tr style={{textAlign:"center"}}>
                            <td>{i}</td>
                            <td>{ cuotaPeriodo } </td>
                            <td>{ saldo > -1 ? saldo:0  }</td>
                        </tr>
            } )
              
        }
        
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
              {props}
            </Tooltip>
          );
        
        const vlrVehiculo = this.getPrecio(option2);
        return (
            <Container fluid className="SimuladorCredito">
                <Container fluid>
                    <div eventKey="otro" title="Simular Crédito">
                        <Card className="col-md-12 mx-auto py-3">
                                <Form>
                                    <div className='row'>
                                        <Form.Group as={Col} controlId="formGridState">
                                        <Form.Label>Selecciona el vehiculo que deseas adquirir</Form.Label><br/>
                                            <ButtonGroup aria-label="Basic example" toggle>
                                                {data.map((item,i)=>{
                                                     var imgPrincipal = item.imagenes.principal[0].imagenes[0].filePath;
                                                     const buket = imgPrincipal.toString().split( 'assetsmep' ).length > 1 ? true : false;
                                                     imgPrincipal = imgPrincipal && ! buket ? urlBase + '/static/' + imgPrincipal : imgPrincipal;
                                                    return  <OverlayTrigger key={i}
                                                                placement="bottom"
                                                                delay={{ show: 250, hide: 200 }}
                                                                overlay={renderTooltip(item.nombre)}>
                                                                <ToggleButton 
                                                                    variant="warning" 
                                                                    type="radio" 
                                                                    value={item._id} 
                                                                    checked={option2 === item._id}
                                                                    onChange={(e) => this.setState( {
                                                                        option2: e.currentTarget.value,
                                                                    } )
                                                                }>
                                                                    <img src={`${imgPrincipal}`} alt={item.nombre} className="imagenSimulador" />
                                                                </ToggleButton>
                                                            </OverlayTrigger>
                                                })}
                                                
                                            </ButtonGroup>
                                        </Form.Group>
                                        <Form.Group controlId="cuotaInicial">
                                            <Form.Label>¿Cual será tu cuota inicial?</Form.Label>
                                            <Form.Control type="number" name="cuotaInicial" placeholder="Cuota incial" value={cuotaInicial} onChange={ (e) => this.handleChange(e)}  />
                                        </Form.Group>
                                        <Form.Group controlId="meses">
                                            <Form.Label>¿A cuantos meses deseas el crédito?</Form.Label>
                                            <Form.Control type="number" name="meses" placeholder="Número de meses" value={meses} onChange={ (e) => this.handleChange(e)}  />
                                        </Form.Group>
                                    </div>
                                    <div className='row'>
                                        <Form.Group as={Col} controlId="formGridDate"> 
                                            <Button variant="primary" type="submit" className="float-right" onClick={ (e) => this.calcular(e)}>Calcular</Button>
                                        </Form.Group>
                                    </div>
                                    <div className='row'>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label>Precio del vehiculo</Form.Label><br/>
                                            <h1><AiOutlineDollar />{vlrVehiculo}</h1>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label>Cuota Incial</Form.Label><br/>
                                            <h1>
                                                <AiOutlineDollar />
                                                {cuotaInicial}
                                            </h1>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label>Monto a Financiar</Form.Label><br/>
                                            <h1>
                                                <AiOutlineDollar />
                                               { vlrVehiculo-cuotaInicial }
                                            </h1>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label>Meses</Form.Label><br/>
                                            <h1>{meses}</h1>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formGridAddress1">
                                            <Form.Label>Interés</Form.Label><br/>
                                            <h1>{interes}%</h1>
                                        </Form.Group>
                                    </div>
                                </Form>
                                <table className="table tablaComparativa mb-0">
                                    <thead>
                                        <tr>
                                            <th scope="col">Mes</th>
                                            <th scope="col">Cuota</th>
                                            {/* <th scope="col">Interés</th>
                                            <th scope="col">Abono a capital</th> */}
                                            <th scope="col">Saldo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                          calculado ? <TablaAmortizacion/>: null  
                                        }
                                    </tbody>
                                </table>
                            </Card>
                    </div>
                </Container>
            </Container>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
    {
        settings,
        vehiculos,
    }
) )( Credito );