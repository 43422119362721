import Swal from "sweetalert2";
import { takeLatest } from "redux-saga/effects";
import { enviarMensaje } from '../api';
import { CREATE_MENSAJE_REQUEST } from "../actions";

function* createMensaje(payload) {
    try {
        const res = yield enviarMensaje(payload);
        console.log(res.data);
        Swal.fire(
            'Correcto',
            'Correo enviado con éxito',
            'success'
        );
    } catch ( error ) {
        console.log(error);
        Swal.fire(
            'Error',
            'Ocurrio un error al enviar el correo',
            'error'
        );
    }
}

// Watcher
export default function* portafolioSaga() {
    yield takeLatest( CREATE_MENSAJE_REQUEST, createMensaje );
}

