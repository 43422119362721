import React, { Component } from 'react';
import { Player, ControlBar } from 'video-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/** BOOTSTRAP */
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';

/*ASSETS */
import Bici from '../../assets/images/city.png';
import Moto from '../../assets/images/NQi-GTS-NGTS.png';
import Pati from '../../assets/images/tiendaPati.png';
import Casco from '../../assets/images/tiendaAcc2.png';
import Carro from '../../assets/images/RICH_6_EV_BLANCA.png';
import Config from '../../utils/configuracion';
import 'react-slideshow-image/dist/styles.css';
import { constCongif } from '../../utils/Constants';

import { FaVolumeMute, FaVolumeUp } from 'react-icons/fa';

/**ANIMACIONES */
import { AnimarEntrada } from '../Animaciones/AnimarEntrada';

import { getComparaciones as actionGetComparaciones } from '../../redux/actions';
// import InstaGallery from '../InstaGallery';

class Inicio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indexVideos: 1,
            audio: false,
            dataComparasiones: [],
        }
        this.cambioVideo = this.cambioVideo.bind(this);
    }

    componentDidMount() {
        const {
            getComparaciones,
        } = this.props;

        this.setState({
            reproductores: [
                this.player0,
                this.player1,
                this.player2,
            ]
        })
        if (!this.state.isLoading && !this.state.isLoaded) {
            this.setState({
                isLoading: true,
            }, () => {
                getComparaciones();
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            comparaciones,
        } = this.props;

        const { indexVideos, reproductores, audio } = this.state;

        if (indexVideos !== prevState.indexVideos) {
            reproductores.map((item, i) => {
                i === indexVideos ? item.play() : item.pause();
                item.seek(0)
            })
        }

        if (comparaciones.comparaciones !== prevProps.comparaciones.comparaciones) {
            this.setState({
                dataComparasiones: comparaciones.comparaciones,
                isLoading: false,
                isLoaded: true,
            });
        }
    }

    cambioVideo = (selectedIndex, e) => {
        this.setState({ indexVideos: selectedIndex });
    };
    render() {
        const { indexVideos, audio } = this.state;
        const urlBase = constCongif.url.API_URL;
        const Imagen = `url(${urlBase}/static/assets/DSC04147.jpg)`

        return (
            <>
                <Row className="px-0 mx-0">
                    <Col md={12} className="cabecera p-0">
                        <div className="contVideo">
                            <Carousel
                                easing="ease"
                                autoPlay={false}
                                activeIndex={indexVideos}
                                onSelect={this.cambioVideo}>
                                <Carousel.Item>
                                    <Player
                                        ref={player => {
                                            this.player0 = player;
                                        }}
                                        src={`${urlBase}/static/assets/MEEPBicicletas.mp4`}
                                        className="videoInicio"
                                        //autoPlay={indexVideos === 0 ? true:false}

                                        loop
                                        muted={true}
                                    >
                                        <ControlBar disableCompletely={true} />
                                    </Player>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Player
                                        ref={player => {
                                            this.player1 = player;
                                        }}
                                        src={`${urlBase}/static/assets/MEEPMotos.mp4`}
                                        className="videoInicio"
                                        autoPlay={indexVideos === 1 ? true : false}
                                        loop
                                        muted={true}
                                    >
                                        <ControlBar disableCompletely={true} />
                                    </Player>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Player
                                        ref={player => {
                                            this.player2 = player;
                                        }}
                                        src={`${urlBase}/static/assets/MEEPPatinetas.mp4`}
                                        className="videoInicio"
                                        autoPlay={indexVideos === 2 ? true : false}
                                        loop
                                        muted={true}
                                    >
                                        <ControlBar disableCompletely={true} />
                                    </Player>
                                </Carousel.Item>
                            </Carousel>
                        </div>
                        <AnimarEntrada timeout={2000} tipoAnimacion="aparecer" >
                            <div className="tituloBase col-md-5">
                                <h1>Súbete a la movilidad eléctrica</h1>
                                <h5>No gastes más tiempo y dinero en combustibles contaminantes, dale energía a tu transporte y vive la nueva generación del cambio. Vive #Meep</h5>
                                <Button className="btnBase"><Link to="/tienda/">Ver Productos</Link></Button>
                            </div>
                        </AnimarEntrada>
                        {/* <div className="position-absolute" style={{bottom:"30px", right:"30px", zIndex:"2"}} onClick={(e)=>this.setState({audio:!audio})}>{ audio ? <FaVolumeMute size={52} />: <FaVolumeUp size={52} /> }</div> */}
                    </Col>
                </Row>
                <Row className="section section-categorias px-0 mx-0">
                    <Col lg={6} xxl={3} className="motociclosClr ">
                        <Link to="/tienda/categorias?cat=motocicletas">
                            <Row>
                                <Col md={7} className="px-1 text-center">
                                    <img src={Moto} alt="Motocicletas" />
                                </Col>
                                <Col md={5} className="px-0">
                                    <Container className="contentInfo" >
                                        <h4>{Config.motocicletas.entrada}</h4>
                                        <h1>Motociclos</h1>
                                        <h2>{Config.motocicletas.titular}</h2>
                                        <Button className="float-right mt-4">Ver más</Button>
                                    </Container>
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                    {/* <Col lg={6} xxl={3} xl={6} className="CarrosClr">
                        <Link to="/tienda/categorias?cat=automoviles">
                            <Row>
                                <Col md={7} className="px-1 text-center">
                                    <img src={Carro} alt="Automoviles" />
                                </Col>
                                <Col md={5} className="px-0">
                                    <Container className="contentInfo" >
                                        <h4>{Config.motocicletas.entrada}</h4>
                                        <h1>Carros</h1>
                                        <h2>{Config.motocicletas.titular}</h2>
                                        <Button className="float-right mt-4">Ver más</Button>
                                    </Container>
                                </Col>
                            </Row>
                        </Link>
                    </Col> */}
                    <Col lg={6} xxl={3} className="BicicletasClr">
                        <Link to="/tienda/categorias?cat=bicicletas">
                            <Row>
                                <Col md={5} className="px-0 text-center" >
                                    <img src={Bici} alt="Bicicletas" style={{ maxWidth: "125%" }} className="imgCatBicicleta" />
                                </Col>
                                <Col md={7}>
                                    <Container className="contentInfo">
                                        <h4>{Config.bicicletas.entrada}</h4>
                                        <h1>Bicicletas</h1>
                                        <h2>{Config.bicicletas.titular}</h2>
                                        <Button className="float-right mt-4">Ver más</Button>
                                    </Container>
                                </Col>
                            </Row>
                        </Link>

                    </Col>
                    <Col lg={6} xxl={3} className="PatinetasClr">
                        <Link to="/tienda/categorias?cat=patinetas">
                            <Row>
                                <Col md={5} className="text-center" >
                                    <img src={Pati} alt="Patinetas" />
                                </Col>
                                <Col md={7} >
                                    <Container className="contentInfo">
                                        <h4>{Config.patinetas.entrada}</h4>
                                        <h1>Patinetas</h1>
                                        <h2>{Config.patinetas.titular}</h2>
                                        <Button className="float-right mt-4">Ver más</Button>
                                    </Container>
                                </Col>
                            </Row>
                        </Link>

                    </Col>
                    <Col lg={6} xxl={3} className="AccesoriosClr">
                        <Link to="/tienda/categorias?cat=accesorios">
                            <Row>
                                <Col md={5} >
                                    <img src={Casco} alt="Accesorios" style={{ maxWidth: "100%" }} />
                                </Col>
                                <Col md={7} >
                                    <Container className="contentInfo">
                                        <h4>{Config.accesorios.entrada}</h4>
                                        <h1>Accesorios</h1>
                                        <h2>{Config.accesorios.titular}</h2>
                                        <Button className="float-right mt-4">Ver más</Button>
                                    </Container>
                                </Col>
                            </Row>
                        </Link>
                    </Col>
                </Row>
                {/* <InstaGallery /> */}
                <Row className="pb-3 px-0 mx-0">
                    <Col md={6}>
                        <h3 className="subtitulo m-5">¿Qué esperas para montarte en la onda de la movilidad eléctrica?</h3>
                        <img src={`${urlBase}/static/assets/moto.svg`} alt="Ilustración motocicleta" />
                    </Col>
                    <Col md={6} className="row">
                        <Row>
                            <Col md={8} className="m-auto">
                                <h2>GOAL DUAL CORE</h2>
                            </Col>
                            <Col md={4} className="text-center">
                                <img src={`${urlBase}/static/assets/motor.jpg`} alt="Ilustración motocicleta" style={{ maxHeight: "200px", width: "100%" }} />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col>
                                <h5>TODOS LOS MOTOCICLOS TIENEN MOTOR BOSCH</h5>
                                <ul className="listaInicio">
                                    <li><img src={`${urlBase}/static/assets/icono1.svg`} alt="Ícono" />Mejor aceleración y control para ascenso de pendientes.</li>
                                    <li><img src={`${urlBase}/static/assets/icono2.svg`} alt="Ícono" />Control automático de aceleración.</li>
                                    <li><img src={`${urlBase}/static/assets/icono3.svg`} alt="Ícono" />Ajuste automático de frenado según las condiciones de manejo.</li>
                                    <li><img src={`${urlBase}/static/assets/icono4.svg`} alt="Ícono" />Sistema de recarga con frenado y desaceleración, hasta 5kms extra por carga.</li>
                                    <li><img src={`${urlBase}/static/assets/icono5.svg`} alt="Ícono" />Ajuste automático anti derrape en manejo bajo la lluvia.</li>
                                    <li><img src={`${urlBase}/static/assets/icono6.svg`} alt="Ícono" />Mejor gestión de la potencia del motor y mayor cuidado a la vida útil de la batería.</li>
                                    <li><img src={`${urlBase}/static/assets/icono7.svg`} alt="Ícono" />Calidad superior y mayor confiabilidad a prueba de fallas.</li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="px-0 mx-0">
                    <Col md={7} className="my-3">
                        <table className="table tablaComparativa mx-auto mb-0">
                            <thead>
                                <tr>
                                    <th scope="col"><img src={`${urlBase}/static/assets/TransportePublico.svg`} alt="Trasnporte Público" />Transporte Público</th>
                                    <th scope="col"><img src={`${urlBase}/static/assets/Motociclos.svg`} alt="Motociclos Eléctricos" />Motociclos Eléctricos</th>
                                    <th scope="col"><img src={`${urlBase}/static/assets/Bicicletas.svg`} alt="Bicicletas eléctricas" />Bicicletas eléctricas</th>
                                    <th scope="col"><img src={`${urlBase}/static/assets/Patinetas.svg`} alt="Patinetas eléctricas" />Patinetas eléctricas</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature1}</td>)
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature2}</td>)
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature3}</td>)
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature4}</td>)
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature5}</td>)
                                    }
                                </tr>
                                <tr>
                                    {
                                        this.state.dataComparasiones.map(item => <td>{item.feature6}</td>)
                                    }
                                </tr>
                                {/* <tr>
                                    <td>4 viajes por día <br/>88 viajes al mes</td>
                                    <td>Recorrido de 700Km/mes</td>
                                    <td>Recorrido de 700Km/mes</td>
                                    <td>Recorrido de 700Km/mes</td>
                                </tr>
                                <tr>
                                    <td>Promedio de 22 días hábiles</td>
                                    <td>Promedio de 22 días hábiles</td>
                                    <td>Promedio de 22 días hábiles</td>
                                    <td>Promedio de 22 días hábiles</td>
                                </tr>
                                <tr>
                                    <td>Costo del pasaje en bus:$2.350</td>
                                    <td>Recorrido promedio de 32Km/día</td>
                                    <td>Recorrido promedio de 32Km/día</td>
                                    <td>Recorrido promedio de 32Km/día</td>
                                </tr>
                                <tr>
                                    <td>Transporte Público al mes $206.800</td>
                                    <td>Total de energía/mes $29.744</td>
                                    <td>Total de energía/mes $11.088</td>
                                    <td>Total de energía/mes $19.250</td>
                                </tr>
                                <tr>
                                    <td>Transporte Público al año $2.481.600</td>
                                    <td>Total de energía/año $356.928</td>
                                    <td>Total de energía/año $133.056</td>
                                    <td>Total de energía/año $231.000</td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td><b>NIU N SPORT</b><br/>$6.190.000<br/>25% Cuota inicial
36 Cuotas de $179.226 Mensual<br/><i>Incluye servicio post venta</i></td>
                                    <td><b>CITY 350W</b><br/>$2.090.000<br/>25% Cuota inicial
36 Cuotas de $60.514 Mensual<br/><i>Incluye servicio post venta</i></td>
                                    <td><b>SK2 GT 350W</b><br/>$1.790.000<br/>25% Cuota inicial
36 Cuotas de $51.828 Mensual<br/><i>Incluye servicio post venta</i></td>
                                </tr> */}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ backgroundColor: "#E4650E" }}><img src={`${urlBase}/static/assets/unlike.svg`} alt="Patinetas eléctricas" />Dependencia en movilidad y tiempo</td>
                                    <td colSpan="3" style={{ backgroundColor: "#83A328" }}><img src={`${urlBase}/static/assets/like.svg`} alt="Patinetas eléctricas" />Independencia en movilidad y tiempo</td>
                                </tr>
                            </tfoot>
                        </table>
                    </Col>
                    <Col md={5} style={{ backgroundImage: Imagen }} className="imagenAcompanamiento mt-3" >

                    </Col>
                </Row>
                {/* <Row className="section clara py-4" >
                    <Col md={3} className="cabecera container destacados-inicio">
                        <h1>Destacados del mes</h1> 
                        <span>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span>
                    </Col>
                    <Col md={9}>
                        <Row>
                            <Col md={4}>
                                <div className="destacadoInicio">
                                    <Container className="ContInfoDestacada">
                                        <h1 style={{color:'#FFB100'}}>1</h1>
                                        <span>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span>
                                        <Button className="btnBase amarillo">Ver Producto</Button>
                                    </Container>
                                    <img src={imgDestacada1} alt="Imagen destacada"/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="destacadoInicio">
                                    <Container className="ContInfoDestacada">
                                        <h1 style={{color:'white'}}>2</h1>
                                        <span>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span>
                                        <Button className="btnBase" style={{background:'#656565'}}>Ver Producto</Button>
                                    </Container>
                                    <img src={imgDestacada2} alt="Imagen destacada"/>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className="destacadoInicio">
                                    <Container className="ContInfoDestacada">
                                        <h1 style={{color:'#1AC9E2'}}>3</h1>
                                        <span>Lorem ipsum dolor sit amet consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris</span>
                                        <Button className="btnBase azul">Ver Producto</Button>
                                    </Container>
                                    <img src={imgDestacada2} alt="Imagen destacada"/>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>  */}
                {/* <Row className="section descubre">
                    <Container  className="cabecera">
                        <h1 className="float-sm-left pt-5">Descubre</h1>
                        <div className="float-right pt-5">
                            <Button className="btnBase float-right m-1 amarillo">Motos</Button>
                            <Button className="btnBase float-right m-1" style={{background:'#656565'}}>Bicicletas</Button>
                            <Button className="btnBase float-right m-1 azul">Patinetas</Button>
                        </div>
                    </Container>
                    <Container>
                        <Row className="m-auto">
                            <Col md={3} ><img src={cuadrado1} className="w-100" alt="img"/></Col>
                            <Col md={6}><img src={rectangulo1} className="w-100" alt="img"/></Col>
                            <Col md={3} ><img src={cuadrado1} className="w-100" alt="img"/></Col>
                        </Row>
                        <Row className="m-auto pt-4">
                            <Col md={6} ><img src={rectangulo2} className="w-100" alt="img"/></Col>
                            <Col md={3} ><img src={cuadrado2} className="w-100" alt="img"/></Col>
                            <Col md={3} ><img src={cuadrado2} className="w-100" alt="img"/></Col>
                        </Row>
                    </Container>
                </Row> */}
            </>
        )
    }
}

export default connect(({ settings, comparaciones }) => (
    {
        settings,
        comparaciones,
    }
), { getComparaciones: actionGetComparaciones })(Inicio);