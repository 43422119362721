import React, { Component } from 'react'
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

/** BOOTSTRAP */
import {Container,Row,Col} from 'react-bootstrap';

/**Icons */
import {FcEngineering} from 'react-icons/fc';
import { SiCoronaengine } from "react-icons/si";
import {IoSpeedometerOutline, IoResize} from "react-icons/io5";
import {FaCarBattery, FaStop, FaWeightHanging, FaCode} from "react-icons/fa";
import {GiBatteryPackAlt, GiTireTracks} from "react-icons/gi"
import {MdScreenLockLandscape} from "react-icons/md";

class Especificaciones extends Component {
    constructor( props ) {
        super( props );
        const { vehiculos } = this.props;
        this.state = {
            destacados:[],
            descripcion:"",
            superior:"",
            principal:"",
            nombre:"",
        }
    }

    componentDidMount(){
        this.asignarEstado();
    }

    componentDidUpdate( prevProps ) {
        const { vehiculos } = this.props;
        if(vehiculos.current !== prevProps.vehiculos.current){
            this.asignarEstado()
        }
    }

    asignarEstado(){
        const {vehiculos} = this.props;
        this.setState( {
            descripcion: vehiculos.current.descripcion ? vehiculos.current.descripcion : [],
            destacados:vehiculos.current.destacados ? vehiculos.current.destacados : [],
            nombre: vehiculos.current.nombre ? vehiculos.current.nombre : "",
            superior: vehiculos.current.titular ? vehiculos.current.titular.superior : "",
            principal: vehiculos.current.titular ? vehiculos.current.titular.principal : "",
        } );
        
        
    }

    render() {
        const { descripcion, destacados, superior, principal, nombre } = this.state;
        return (
            <Container className="Datos-Producto-Especificaciones">
                <Row>
                    <Col md={3}>
                        <div className="tituloBase">
                            <span>{ superior }</span>
                            <h1>{ nombre }</h1> 
                            <h2>{ principal }</h2>  
                        </div>
                        <p dangerouslySetInnerHTML={ {__html: descripcion} } />
                    </Col>
                    <Col md={9} className="Contenedor-Detalles">
                        <Row>
                            {destacados.map((item, i)=>{
                                return  <Col sm={ 4 } key={i} className="pb-5">
                                            {
                                                item.dato?(
                                                {
                                                'Velocidad': <IoSpeedometerOutline size={52} color/>,
                                                'Batería': <FaCarBattery size={52}/>,
                                                'Autonomía': <SiCoronaengine size={52}/>,
                                                'Motor': <SiCoronaengine size={52}/>,
                                                'Tiempo de Carga': <GiBatteryPackAlt size={52}/>,
                                                'Freno': <FaStop size={52}/>,
                                                'Peso Neto': <FaWeightHanging size={52}/>,
                                                'Capacidad de Carga': <FcEngineering size={52}/>,
                                                'Llantas': <GiTireTracks size={52}/>,
                                                'Tablero': <MdScreenLockLandscape size={52}/>,
                                                'Controlador': <FaCode size={52}/>,
                                                'Dimensiones ( L x W x H)': <IoResize size={52}/>
                                                }[item.dato.label]
                                                ) :null
                                            }
                                            <h4 style={{color:"#de4d00"}}>{item.dato ? item.dato.label : "" }</h4>
                                            <h5 style={{color:"#7d9417"}}>{item.valor}</h5>
                                        </Col>
                            })}
                            
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default connect( ( { settings, vehiculos } ) => (
    {
        settings,
        vehiculos,
    }
) )( withRouter( Especificaciones ) );