// Constants.js
const prod = {
    url: {
        API_URL: 'http://54.163.166.123:4000',
    },
};

const dev = {
    url: {
        //API_URL: 'http://localhost:4000',
        API_URL: 'http://54.163.166.123:4000',
    },
};

export const constCongif = process.env.NODE_ENV === 'development' ? dev : prod;
