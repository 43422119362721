/**
 * External Dependencies
 */
import { combineReducers } from 'redux';
import Cookies from 'js-cookie';

/**
 * Internal Dependencies
 */
import {
    UPDATE_SETTINGS,
    SOLICITAR_REGISTROS,
    REGISTROS_RECIBIDOS,
    CARGANDO_REGISTROS,
    CURRENT_REGISTRO,
    GET_COMPARACIONES_SUCCESS,
    GET_ENTRADAS_SUCCESS,
    GET_ENTRADA_SUCCESS,
    CONTACTO_RESPONSE
} from '../actions';

import {
    ERROR_CARGAR_REGISTROS,
    FORMATEAR_REGISTROS,
} from "../ActionTypes"; 

// initial state.
const INITIAL_SETTINGS_STATE = {
    detalles: ''
};

const INITIAL_VEHICULOS_STATE = {
    data: [],
    cargando: false,
    error: false,
    isLoading: false,
    cargado: false,
    current:{},
};

const INITIAL_USER_DATA = {
    userName: "",
    nombres: "",
    apellidos: "",
    imgProfile: "http://localhost:3000/static/media/avatar-1-profile.382606b5.png",
    descripcion: undefined,
    isLoading: false,
};

const INITIAL_COMPARACIONES_STATE = {
    comparaciones: [],
    error: false,
    isLoading: false,
    isLoaded: false,
};

const INITIAL_ENTRADAS_STATE = {
    entradas: [],
    entrada: {},
    error: false,
    isLoading: false,
    isLoaded: false,
};

const INITIAL_CONTACTO = {}
/**
 * Reducer
 */
const appReducer = combineReducers( {
    settings: ( state = INITIAL_SETTINGS_STATE, action ) => {
        switch ( action.type ) {
        case UPDATE_SETTINGS:
            return Object.assign( {}, state, action.settings );
        default:
            return state;
        }
    },
    contacto: ( state = INITIAL_CONTACTO, action ) => {
        switch ( action.type ) {
        case CONTACTO_RESPONSE:
            return Object.assign( {}, state, action.settings );
        default:
            return state;
        }
    },
    vehiculos: ( state = INITIAL_VEHICULOS_STATE, action ) => {
        switch ( action.type ) {
        case CARGANDO_REGISTROS:
        case SOLICITAR_REGISTROS:
            return {
                ...state,
                cargando: true,
            };
        case REGISTROS_RECIBIDOS:
            return {
                ...state,
                cargando: false,
                cargado:true,
                data: action.payload,
            };
        case CURRENT_REGISTRO:
            return {
                ...state,
                current: action.payload,
            };
        case ERROR_CARGAR_REGISTROS:
            return {
                ...state,                    
                isLoading: false,
                error: true,
            };
        case FORMATEAR_REGISTROS:
            return { ...state };
        default:
            return state;
        }
    },
    user: ( state = INITIAL_USER_DATA, action ) => {
        switch ( action.type ) {
        
        default:
            return state;
        }
    },
    comparaciones: ( state = INITIAL_COMPARACIONES_STATE, action ) => {
        switch ( action.type ) {
        case GET_COMPARACIONES_SUCCESS:            
            return {
                ...state,
                comparaciones: action.payload,
                isLoading: false,
                isLoaded: true,
            };
        default:
            return state;
        }
    },
    entradas: ( state = INITIAL_ENTRADAS_STATE, action ) => {
        switch ( action.type ) {
        case GET_ENTRADAS_SUCCESS:            
            return {
                ...state,
                entradas: action.payload,
                isLoading: false,
                isLoaded: true,
            };
        case GET_ENTRADA_SUCCESS:            
            return {
                ...state,
                entrada: action.payload,
            };
        default:
            return state;
        }
    },
} );

const rootReducer = ( state, action ) => {
    if ( action.type === 'LOGOUT_SUCCESS' ) {
        state = undefined;
    }
    return appReducer( state, action );
};

export default rootReducer;
