import React from "react";

/** BOOTSTRAP */
import { Container, Row, Col, Form, Button, Carousel } from "react-bootstrap";

import { constCongif } from "../../utils/Constants";
import { createMensaje } from "../../redux/actions";
import { useDispatch } from "react-redux";

const Contacto = () => {
  const dispatch = useDispatch();
  const urlBase = constCongif.url.API_URL;

  const sendForm = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formObject = Object.fromEntries(data.entries());
    dispatch(createMensaje({
      datos: formObject,
      asunto: 'Contacto',
      tipo: "contacto"
    }));
  };

  return (
    <Container fluid className="Contacto">
      <Row className="banner position-relative">
        <Col md={12} className="px-0">
          <Carousel easing="ease" autoPlay={false}>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner5.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${urlBase}/static/assets/banner6.jpg)`,
                }}
                className="bannerInterno"
              ></div>
            </Carousel.Item>
          </Carousel>
        </Col>
        <Col md={{ span: 6, offset: 1 }} className="position-absolute">
          <h4>Movilidad Eléctrica Energía de Pereira - MEEP</h4>
          <h1>Muévete por el futuro</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="contactInfo"></Col>
        <Col md={5} className="m-auto py-3">
          <h2>Contacta a nuestro equipo</h2>
          <Form className="formulario-TestDrive" onSubmit={sendForm}>
            <div className="form-row">
              <Form.Group as={Col} controlId="formGridName">
                <Form.Label>Nombres</Form.Label>
                <Form.Control type="text" id="nombre" name="nombre" placeholder="Ingrese su nombre" />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridLastName">
                <Form.Label>Apellidos</Form.Label>
                <Form.Control type="text" id="apellidos" name="apellidos" placeholder="Ingrese sus apellidos" />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridTel">
                <Form.Label>Teléfono</Form.Label>
                <Form.Control type="text" id="telefono" name="telefono" placeholder="Móvil o fijo" />
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Group as={Col} controlId="formGridEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="Email" id="email" name="email" placeholder="Email válido" />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridAddress">
                <Form.Label>Dirección</Form.Label>
                <Form.Control placeholder="1234 Calle Principal" id="direccion" name="direccion" />
              </Form.Group>
              <Form.Group as={Col} controlId="formGridCity">
                <Form.Label>Ciudad</Form.Label>
                <Form.Control d="ciudad" name="ciudad"/>
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Label>Mensaje</Form.Label>
              <Form.Control as="textarea" rows={3} d="mensaje" name="mensaje" />
            </div>
            <div className="form-row mt-4">
              <Form.Group as={Col} controlId="formGridDate">
                <Button variant="primary" type="submit" className="float-right">
                  Enviar
                </Button>
              </Form.Group>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default Contacto;
