
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const updateSettings = ( settings ) => ( {
    type: UPDATE_SETTINGS,
    settings,
} );


export const SOLICITAR_REGISTROS = 'SOLICITAR_REGISTROS';
export const solicitarRegistros = payload => ( {
    type: SOLICITAR_REGISTROS,
    ...payload,
} );

export const REGISTROS_RECIBIDOS = 'REGISTROS_RECIBIDOS';
export const registrosRecibidos = payload => ( {
    type: REGISTROS_RECIBIDOS,
    ...payload,
} );

export const CURRENT_REGISTRO = 'CURRENT_REGISTRO';
export const currentRegistro = (payload) => ( {
    type: CURRENT_REGISTRO,
    payload,
} );

export const CONTACTO_RESPONSE = 'CONTACTO_RESPONSE';
export const REGISTRAR_CONTACTO = 'REGISTRAR_CONTACTO';
export const registrarContacto = (data) => ( {
    type: REGISTRAR_CONTACTO,
    data,
} );



// COMPARACIONES
export const GET_COMPARACIONES_REQUEST = 'GET_COMPARACIONES_REQUEST';
export const GET_COMPARACIONES_SUCCESS = 'GET_COMPARACIONES_SUCCESS';
export const getComparaciones = payload => ( {
    type: GET_COMPARACIONES_REQUEST,
    ...payload,
} );

// ENTRADAS
export const GET_ENTRADAS_REQUEST = 'GET_ENTRADAS_REQUEST';
export const GET_ENTRADAS_SUCCESS = 'GET_ENTRADAS_SUCCESS';
export const getEntradas = payload => ( {
    type: GET_ENTRADAS_REQUEST,
    ...payload,
} );

export const GET_ENTRADA_REQUEST = 'GET_ENTRADA_REQUEST';
export const GET_ENTRADA_SUCCESS = 'GET_ENTRADA_SUCCESS';
export const getEntrada = id => ( {
    type: GET_ENTRADA_REQUEST,
    id,
} );

// MENSAJES
export const CREATE_MENSAJE_REQUEST = 'CREATE_MENSAJE_REQUEST';
export const createMensaje = payload => ( {
    type: CREATE_MENSAJE_REQUEST,
    ...payload,
} );

export const CARGANDO_REGISTROS = 'CARGANDO_REGISTROS';
