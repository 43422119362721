const Config = {
    motocicletas:{
        nombre:"Motociclos",
        claseBase:"clsMotos",
        titular:"Cero emisiones, cero ruido",
        entrada:"",
        detalle:"",
    },
    bicicletas:{
        nombre:"Bicicletas",
        claseBase:"clsBici",
        titular:"Ahorras tiempo y dinero",
        entrada:"",
        detalle:"",
    },
    patinetas:{
        nombre:"Patinetas",
        claseBase:"clsPat",
        titular:"Muévete con energía",
        entrada:"",
        detalle:""
    },
    accesorios:{
        nombre:"Accesorios",
        claseBase:"clsAcc",
        titular:"Acompaña tu movimiento",
        entrada:"",
        detalle:""
    },
    automoviles:{
        nombre:"Automoviles",
        claseBase:"clsAcc",
        titular:"Acompaña tu movimiento",
        entrada:"",
        detalle:""
    }
}

export default Config