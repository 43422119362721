import { all } from 'redux-saga/effects';

import vehiculosSaga from './vehiculosSaga';
import comparacionesSaga from './comparacionesSaga';
import entradasSaga from './entradasSaga';
import mensajesSaga from './mensajesSaga';

export default function* rootSaga() {
    yield all( [
        vehiculosSaga(),
        comparacionesSaga(),
        entradasSaga(),
        mensajesSaga(),
    ] );
}
