import React from "react";

/**ASSETS */
import circuloEstadistica from "../../assets/images/circuloEstadistica.png";
import Iframe from "react-iframe";
import Mapa from './Mapa';
/** BOOTSTRAP */
import { Container, Row, Col, Table } from "react-bootstrap";
import BannerTitulo from '../../assets/MAPA.jpg';
import BannerIndicaciones from '../../assets/images/indicacionesMovilidad.png';

export const PuntosCarga = () => {
  return (
    <Container fluid className="PuntosCarga">
      <Row>
        <Col md={12} className="px-0 text-center">
          <img src={BannerTitulo} alt="Puntos de Carga" style={{ maxWidth: "90%" }} />
          
        </Col>
        <Col md={12} className="px-0 text-center pb-3">
          <img src={BannerIndicaciones} alt="Puntos de Carga" />
        </Col>
      </Row>
    </Container>
  );
};


export default PuntosCarga;
